import { Box, Grid, Typography } from "@mui/material";
import OverViewMainCard from "../../components/OverViewMainCard";
import { useFetchPayments } from "../../services/apis/overview";
import LoadingSpinner from "../../components/LoadingSpinner";
import PaymentCard from "../../components/PaymentCard";

const PaymentTabPanel = () => {
  const today = new Date();
  const date14DaysAgo = new Date();
  date14DaysAgo.setDate(today.getDate() - 14);
  const { data: paymentData, isFetching } = useFetchPayments();
  return (
    <div className="h-full">
      <Box>
        <Typography
          sx={{ color: "#808080", fontSize: "16px", fontWeight: "500" }}
        >
          OverView
        </Typography>
      </Box>
      {/* users overview */}
      {/* {isFetching ? (
        <LoadingSpinner />
      ) : ( */}
      <Grid container spacing={1.5} pt={4}>
        {/* Main Cards Start  */}
        <Grid item lg={4} xs={12} sm={12} md={4} p={1}>
          <PaymentCard
            backgroundColor="#FFFFFF"
            title="Total Pending Payment (Deposit)"
            totalRequests={
              isFetching ? (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              ) : (
                paymentData?.pending?.count?.toLocaleString()
              )
            }
            totalMoney={
              isFetching ? (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              ) : (
                paymentData?.pending?.amount?.toLocaleString()
              )
            }
            value={"Request"}
            egp={"EGP"}
          />
        </Grid>
        <Grid item lg={4} xs={12} sm={12} md={4} p={1}>
          <PaymentCard
            backgroundColor="#FFFFFF"
            title="Approved Payments"
            totalRequests={
              isFetching ? (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              ) : (
                paymentData?.approved?.count?.toLocaleString()
              )
            }
            totalMoney={
              isFetching ? (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              ) : (
                paymentData?.approved?.amount?.toLocaleString()
              )
            }
            value={"Request"}
            egp={"EGP"}
          />
        </Grid>
        <Grid item lg={4} xs={12} sm={12} md={4} p={1}>
          <PaymentCard
            backgroundColor="#FFFFFF"
            title="Rejected Payments"
            totalRequests={
              isFetching ? (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              ) : (
                paymentData?.rejected?.count?.toLocaleString()
              )
            }
            totalMoney={
              isFetching ? (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              ) : (
                paymentData?.rejected?.amount?.toLocaleString()
              )
            }
            value={"Request"}
            egp={"EGP"}
          />
        </Grid>
        {/* End Main Cards  */}
      </Grid>
      {/* )} */}

      {/* end users overview */}
    </div>
  );
};
export default PaymentTabPanel;
