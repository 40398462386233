import { useQuery } from "react-query";
import { axiosInstance as axios } from "../config";

export const fetchTickets = async (q) => {
  try {
    const response = await axios.get(`/api/contactUs/index`, {
      params: {
        page: q.page,
      },
    });
    return response.data.data; // Assuming you want to return the data part of the response
  } catch (error) {
    throw error;
  }
};

export const useFetchTickets = (q) => {
  return useQuery(["tickets", q], () => fetchTickets(q), {
    // You can add additional options here, such as refetching intervals, etc.
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};
export const fetchUserTickets = async ({ queryKey }) => {
  const [
    ,
    {
      id,
      page: { page },
    },
  ] = queryKey;
  const response = await axios.get(`/api/contactUs/index`, {
    params: {
      user_id: id,
      page: page,
    },
  });
  return response?.data?.data;
};
export const useUserTickets = (id, page) => {
  return useQuery(["userTransactions", { id, page }], fetchUserTickets, {
    keepPreviousData: true,
    enabled: !!id,
    refetchOnWindowFocus: false,
  });
};
const fetchTicketsDetails = async (id) => {
  try {
    const response = await axios.get(`/api/contactUs/findDetailsById/${id}`);
    return response.data?.data;
  } catch (error) {
    throw error;
  }
};

export const useTicketDetails = (id) => {
  return useQuery(["ticketDetails", id], () => fetchTicketsDetails(id), {
    enabled: !!id, // Only run the query if id is not null or undefined
  });
};
export const fetchSubQuestionsDetails = async (id) => {
  try {
    const response = await axios.get(
      `/api/contactUsQuestions/findDetails/${id}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchAssignedAdmins = async () => {
  try {
    const response = await axios.get(`/api/admin/dropdown`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const addReplay = async (body) => {
  try {
    const response = await axios.post("/api/contactUs/adminReply", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const addTicketQuestion = async (body) => {
  try {
    const response = await axios.post("/api/contactUsQuestions/add", body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const addSubTicketQuestion = async (body) => {
  try {
    const response = await axios.post("/api/contactUsQuestions/addSub", body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const editSubQuestion = async (id, body) => {
  try {
    const response = await axios.post(
      `/api/contactUsQuestions/edit/${id}`,
      body
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const reassign = async (body) => {
  try {
    const response = await axios.post("/api/contactUs/reassign", body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const adminChangeStatus = async (body) => {
  try {
    const response = await axios.post("/api/contactUs/adminChangeStatus", body);
    return response;
  } catch (error) {
    throw error;
  }
};
const fetchTicketQuestions = async (q) => {
  try {
    const response = await axios.get(`/api/contactUsQuestions/index`, {
      params: {
        page: q.page,
      },
    });
    return response.data?.data; // Return the data directly
  } catch (error) {
    throw error;
  }
};
export const useTicketQuestions = (q) => {
  return useQuery(["ticketQuestions", q], () => fetchTicketQuestions(q), {});
};
export const deleteTicketQuestion = async (id) => {
  try {
    const response = await axios.get(`/api/contactUsQuestions/delete/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
