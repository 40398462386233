import { useNavigate, useParams } from "react-router-dom";
import DashboardMainHeader from "../../../components/DashboardMainHeader";
import arrowLeft from "../../../svg/arrow-circle-left.svg";
import TextView from "../../../components/common/TextView";
import { formDate } from "../../../services/helpers/index";
import {
  useTransactionDetails,
  useChangeTransactionStatus,
} from "../../../services/apis/transactions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useState } from "react";
import TextFieldComponent from "../../../components/TextFieldComponent";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";
import CustomDialog from "../../../components/CustomDialog";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import RejectionDialog from "./RejectionDialog";

const WithDrawPending = () => {
  const mutation = useChangeTransactionStatus();

  const navigate = useNavigate();
  const { id } = useParams();
  const { data: transactionDetails, isFetching } = useTransactionDetails(id);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    reference: "",
    amount: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");

  const handleReferenceNumberChange = (e) => {
    setFormData({
      ...formData,
      reference: e.target.value.replace(/[\u0600-\u06FF\u0750-\u077F]/g, ""),
    });
  };
  const handleAccept = async () => {
    try {
      setTransactionStatus("approved");
      if (!formData?.amount || !formData?.reference) {
        dispatch(
          setData({
            openSnack: true,
            message: "Please fill in all required fields",
          })
        );
      } else if (isNaN(formData?.amount)) {
        dispatch(
          setData({
            openSnack: true,
            message: "Amount must be a number",
          })
        );
      } else {
        setIsSubmitting(true);
        let payload = {
          id: id,
          status: 2,
          amount: +formData?.amount,
          reference_number: formData?.reference,
        };

        if (transactionDetails?.account) {
          payload = {
            ...payload,
            account: transactionDetails?.account,
          };
        }
        await mutation.mutateAsync(payload);
        setSuccessDialogOpen(true);
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleReject = async () => {
    try {
      if (!rejectionReason) {
        dispatch(
          setData({
            openSnack: true,
            message: "Please Select Rejection Reason",
          })
        );
      } else {
        setIsSubmitting(true);
        setTransactionStatus("rejected");
        const payload = {
          id: id,
          status: 3,
          rejection_reason: rejectionReason,
        };
        await mutation.mutateAsync(payload);
        setSuccessDialogOpen(true);
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  if (isFetching) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <div>
        <DashboardMainHeader />
        <div className="mt-12 mx-6">
          <div className="flex items-center gap-[0.625rem] mb-8">
            <img
              src={arrowLeft}
              className="cursor-pointer"
              alt="Arrow Left"
              onClick={() => {
                navigate("/transactions");
              }}
            />
            <p className="text-2xl text-[#333333] ">Review Withdraw </p>
          </div>
          <div className="bg-white p-12 rounded-xl">
            <div className="grid grid-cols-1 sm:grid-cols-2">
              <TextView
                title={"Transaction ID"}
                value={transactionDetails?.id}
              />{" "}
              <hr className="text-[#e6e6e6] border-t-[1px] md:hidden " />
              <TextView
                title={"User Phone number"}
                value={transactionDetails?.phone}
              />
            </div>
            <hr className="text-[#e6e6e6] border-t-[1px]" />
            <div className="grid grid-cols-1 sm:grid-cols-2">
              <TextView title={"IBAN"} value={transactionDetails?.iban} />{" "}
              <hr className="text-[#e6e6e6] border-t-[1px] md:hidden " />
              <TextView
                title={"Receiver Name"}
                value={transactionDetails?.national_name}
              />
            </div>
            <hr className="text-[#e6e6e6] border-t-[1px]" />
            <div className="grid grid-cols-1 sm:grid-cols-2">
              <TextView
                title={"Transaction Amount"}
                value={`${Number(
                  transactionDetails?.amount
                )?.toLocaleString()} EGP`}
              />{" "}
              <hr className="text-[#e6e6e6] border-t-[1px] md:hidden " />
              <TextView
                title={"Bank name"}
                value={transactionDetails?.bankName}
              />
            </div>
            <hr className="text-[#e6e6e6] border-t-[1px]" />
            <div className="grid grid-cols-1 sm:grid-cols-2">
              <TextView
                title={"Created At"}
                value={formDate(transactionDetails?.created)}
              />
              <TextView
                title={"Updated At"}
                value={formDate(transactionDetails?.modified)}
                hide={transactionDetails?.modified ? false : true}
              />
            </div>
            <hr className="text-[#e6e6e6] border-t-[1px]" />
            <div className="grid grid-cols-1 sm:grid-cols-2">
              <TextView title={"Transaction Status"} value={"Pending"} />
              <hr className="text-[#e6e6e6] border-t-[1px] md:hidden " />
            </div>
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2">
                <TextFieldComponent
                  sx={{ "& fieldset": { border: "none" } }}
                  style={{
                    height: "48px",
                    width: "95%",
                    borderRadius: "9px",
                    backgroundColor: "#F5F5F5",
                  }}
                  name={"Reference Number"}
                  type="text" // Use "text" instead of "number" for more control over allowed characters                name="Reference Number"
                  placeholder="Enter Reference Number"
                  value={formData?.reference}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(
                      /[^a-zA-Z0-9-]/g,
                      ""
                    ); // Remove non-numeric characters
                    setFormData({ ...formData, reference: numericValue });
                  }}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} // Mobile-friendly numeric keyboard
                />

                <TextFieldComponent
                  sx={{ "& fieldset": { border: "none" } }}
                  style={{
                    height: "48px",
                    width: "95%",
                    borderRadius: "9px",
                    backgroundColor: "#F5F5F5",
                  }}
                  type="text" // Use "text" instead of "number" for more control over allowed characters
                  name="Confirm Amount"
                  placeholder="Enter Amount"
                  value={formData?.amount}
                  onChange={(e) => {
                    let value = e.target.value;

                    // Allow only numbers and up to two decimal places
                    if (/^\d*\.?\d{0,2}$/.test(value)) {
                      const numericValue = parseFloat(value);

                      // Check if the number is within the valid range
                      if (numericValue > 0 && numericValue <= 10000000) {
                        setFormData({ ...formData, amount: value });
                      } else if (value === "") {
                        // Allow empty value for clearing the input
                        setFormData({ ...formData, amount: "" });
                      }
                    }
                  }}
                  inputProps={{
                    inputMode: "decimal",
                    pattern: "^[0-9]+(\\.[0-9]{1,2})?$",
                  }}
                />
              </div>
            </div>{" "}
            <div className="flex flex-col sm:flex-row sm:justify-end gap-4 mt-6">
              <Button
                disabled={isSubmitting}
                sx={{
                  width: { xs: "100%", sm: "200px" },
                  height: "43px",
                  borderRadius: "12px",
                  background: "#917244",
                  color: "#FFF",
                  fontSize: "18px",
                  "&:hover": {
                    background: "#917244",
                  },
                  textTransform: "none",
                }}
                onClick={() => {
                  handleAccept();
                }}
              >
                Approve
              </Button>
              <Button
                variant="outlined"
                sx={{
                  width: { xs: "100%", sm: "200px" },
                  height: "43px",
                  borderRadius: "12px",
                  // background: "#CF2424",
                  "&:hover": {
                    borderColor: "#917244",
                    background: "#fff",
                  },
                  borderColor: "#917244",
                  color: "#917244",
                  fontSize: "18px",
                  textTransform: "none",
                }}
                onClick={() => {
                  setOpenAlert(true);
                }}
              >
                Reject
              </Button>
            </div>
          </div>
        </div>
      </div>
      <RejectionDialog
        setRejectionReason={setRejectionReason}
        rejectionReason={rejectionReason}
        openAlert={openAlert}
        handleCloseAlert={() => {
          setOpenAlert(false);
        }}
        handleReject={handleReject}
        message={`Are you sure you want to Reject this Transaction`}
        label={"Reject"}
      />
      <CustomDialog
        open={successDialogOpen}
        onClose={() => {
          setSuccessDialogOpen(false);
        }}
        title={
          transactionStatus === "approved"
            ? "Transaction has been approved successfully."
            : transactionStatus === "rejected"
            ? "Transaction has been rejected successfully."
            : "Transaction status"
        }
        message=""
        buttonText="Money Transactions"
        buttonLink="/transactions"
      />
      <ReusableSnackbar />
    </>
  );
};

export default WithDrawPending;
