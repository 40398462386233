import { useDispatch } from "react-redux";
import TicketMessage from "./TicketMessage";
import { InputAdornment, Skeleton, TextField } from "@mui/material";
import { removeItem } from "../../../store/slices/ticketSlice";
import send2 from "../../../svg/send2.svg";
import closecircle from "../../../svg/closecirclered.svg";
import { useRef, useState } from "react";

const SecondTicketPanel = ({
  containerRef,
  data,
  formData,
  handleKeyPress,
  setFormData,
  handleSave,
  isLoading,
  handleChange,
  image,
  setFile,
  setImage,
}) => {
  const dispatch = useDispatch();
  dispatch(removeItem(data?.id));
  const [inputKey, setInputKey] = useState(0);
  const fileInputRef = useRef();
  const handleFileInputChange = (event) => {
    handleChange(event);
  };
  const handleDeleteImage = () => {
    setImage(null);
    setFile(null);
    setInputKey((prevKey) => prevKey + 1); // Reset the file input field
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <div className="flex flex-col items-center">
      <div
        ref={containerRef}
        className="pt-2 flex-grow overflow-y-auto  h-[50vh]  flex-col flex scrollbar-hide relative w-full"
      >
        <div className="bg-white">
          <div className=" overflow-y-auto scrollbar-hide  px-6  ">
            {isLoading ? (
              <div className="flex items-center flex-col gap-4">
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
              </div>
            ) : (
              data?.replies?.map((message, index) => (
                <TicketMessage
                  key={`${message?.created} + ${message?.message} + ${index}`}
                  message={message?.message}
                  admin_id={message?.admin_id}
                  created={message?.created}
                />
              ))
            )}
          </div>
        </div>
      </div>
      {isLoading ? (
        <Skeleton sx={{ width: 200 }} />
      ) : (
        data?.status !== 4 && (
          <div className="w-full gap-2 flex items-end">
            <div className="bg-[#F5F5F5] w-full  rounded-xl  ">
              <div className="flex items-center gap-2 rounded-xl   justify-center mt-auto static bottom-0 w-full">
                {image && (
                  <div className="flex p-6 gap-2 mt-auto bg-[#F5F5F5] rounded-xl   static bottom-0 w-full">
                    <div className="flex items-start">
                      <img
                        src={image}
                        alt="Uploaded"
                        className="w-36 h-36 rounded-lg object-cover"
                      />
                      <img
                        onClick={handleDeleteImage}
                        src={closecircle}
                        alt="closecircle"
                        className="cursor-pointer -mt-3 -ml-4"
                      />
                      {/* <button
                       
                        className="text-red-500 text-sm hover:underline"
                      >
                        Delete
                      </button> */}
                    </div>
                  </div>
                )}
              </div>

              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileInputChange}
                key={inputKey}
              />
              {!image && (
                <div className="flex items-center gap-2  justify-center mt-auto static bottom-0 w-full">
                  <TextField
                    sx={{
                      "& fieldset": { border: "none" },
                      height: "48px",
                      borderRadius: "12px",
                      backgroundColor: "#F5F5F5",
                    }}
                    placeholder="Type your replay here ....."
                    fullWidth
                    value={formData.reply}
                    onKeyDown={handleKeyPress}
                    onChange={(event) => {
                      var temppayload = { ...formData };
                      temppayload.reply = event.target.value;
                      setFormData({ ...temppayload });
                    }}
                    InputProps={{
                      endAdornment: !image && formData.reply.trim() === "" && (
                        <InputAdornment position="end">
                          <svg
                            cursor={"pointer"}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => {
                              handleUploadButtonClick();
                            }}
                          >
                            <path
                              d="M12.3291 21.5405C11.2391 21.5405 10.1491 21.1305 9.31906 20.3005C7.65906 18.6405 7.65906 15.9505 9.31906 14.2905L11.7991 11.8205C12.0891 11.5305 12.5691 11.5305 12.8591 11.8205C13.1491 12.1105 13.1491 12.5905 12.8591 12.8805L10.3791 15.3505C9.30906 16.4205 9.30906 18.1705 10.3791 19.2405C11.4491 20.3105 13.1991 20.3105 14.2691 19.2405L18.1591 15.3505C19.3391 14.1705 19.9891 12.6005 19.9891 10.9305C19.9891 9.26055 19.3391 7.69055 18.1591 6.51055C15.7191 4.07055 11.7591 4.07055 9.31906 6.51055L5.07906 10.7505C4.08906 11.7405 3.53906 13.0605 3.53906 14.4605C3.53906 15.8605 4.08906 17.1805 5.07906 18.1705C5.36906 18.4605 5.36906 18.9405 5.07906 19.2305C4.78906 19.5205 4.30906 19.5205 4.01906 19.2305C2.74906 17.9505 2.03906 16.2605 2.03906 14.4605C2.03906 12.6605 2.73906 10.9605 4.01906 9.69055L8.25906 5.45055C11.2791 2.43055 16.1991 2.43055 19.2191 5.45055C20.6791 6.91055 21.4891 8.86055 21.4891 10.9305C21.4891 13.0005 20.6791 14.9505 19.2191 16.4105L15.3291 20.3005C14.4991 21.1305 13.4191 21.5405 12.3291 21.5405Z"
                              fill="#E9C237"
                            />
                          </svg>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              )}
            </div>

            {(formData.reply.trim() !== "" || image) && (
              <div
                onClick={() => {
                  handleSave();
                }}
                className="bg-[#E9C237] rounded-xl flex items-center justify-center h-12 w-12"
              >
                <img src={send2} alt="send2" className="cursor-pointer" />
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default SecondTicketPanel;
