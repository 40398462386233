import { useState } from "react";
import TextFieldComponent from "../../components/TextFieldComponent";
import { Box, Button, Typography } from "@mui/material";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import CustomDialog from "../../components/CustomDialog";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { SecondBox } from "../../components/common/CustomBox";
import { useAddTransactionRejectReason } from "../../services/apis/transactionRejectionReasons";
import CustomTextArea from "../../components/CustomTextArea";
import useWindowSize from "../../hooks/useWindowSize";
import arrowLeft from "../../svg/arrow-circle-left.svg";
import { useNavigate } from "react-router-dom";

const AddNewTransactionRejectReason = () => {
  const addRejectReason = useAddTransactionRejectReason();
  const navigate = useNavigate();

  const [reasonsPayload, setReasonsPayload] = useState({
    reason_ar: "",
    reason_en: "",
  });
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  const handleSave = async () => {
    try {
      if (
        reasonsPayload?.reason_ar === "" ||
        reasonsPayload?.reason_en === ""
      ) {
        dispatch(
          setData({
            openSnack: true,
            message: "Please fill in all fields",
          })
        );

        return;
      }
      setLoading(true);
      await addRejectReason.mutateAsync(reasonsPayload);
      setSuccessDialogOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <DashboardMainHeader />
      <div className="mx-6 font-ibmArabic flex items-center mb-12">
        <h1
          onClick={() => navigate(-1)}
          className="text-sm  cursor-pointer text-[#917244] "
        >
          Rejection Reasons {`>`}{" "}
        </h1>
        <h1 className="text-[#595959] ml-1"> Add Reason</h1>
      </div>
      {/* <Header title="Add Reason" marginL={"25px"} /> */}
      <SecondBox>
        <Box
          m="40px 0 0 0"
          height="auto"
          style={{
            paddingTop: "5%",
            paddingLeft: "5%",
            paddingBottom: "4%",
            width: "90%",
            alignSelf: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              className="cursor-pointer"
              src={arrowLeft}
              alt=""
              onClick={() => navigate(-1)}
            />
            <span className="text-2xl text-[#333333] font-medium ml-2">
              Add Reason
            </span>
          </Box>
          <Box
            sx={{
              paddingLeft: "5%",
              marginTop: "4%",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#4C4C4C",
                  marginBottom: "5px",
                  marginTop: "15px",
                }}
              >
                Reason - English
              </Typography>
              <CustomTextArea
                style={{
                  minHeight: "54px",
                  maxHeight: "200px",
                  // maxWidth: "608px",
                  width: width < 768 ? "100%" : "80%",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "9px",
                  boxShadow: "none",
                  border: "none",
                }}
                name="Reason - English"
                placeholder="eg. New Items"
                value={reasonsPayload?.reason_en}
                onChange={(event) => {
                  let allowedEnglishRegex = /^[a-zA-Z0-9"()@'?!:%.,\-_ ]*$/;
                  if (allowedEnglishRegex.test(event.target.value)) {
                    setReasonsPayload({
                      reason_ar: reasonsPayload.reason_ar,
                      reason_en: event.target.value,
                    });
                  }
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#4C4C4C",
                  marginBottom: "5px",
                  marginTop: "15px",
                }}
              >
                Reason - Arabic
              </Typography>
              <CustomTextArea
                style={{
                  minHeight: "54px",
                  maxHeight: "200px",
                  // maxWidth: "608px",
                  width: width < 768 ? "100%" : "80%",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "9px",
                  boxShadow: "none",
                  border: "none",
                }}
                name="Reason - Arabic"
                placeholder="eg. New Items"
                value={reasonsPayload?.reason_ar}
                onChange={(event) => {
                  let allowedArabicRegex =
                    /^[\u0600-\u06FF0-9"()-_@'!?:%.,\s]*$/;
                  if (allowedArabicRegex.test(event.target.value)) {
                    setReasonsPayload({
                      reason_en: reasonsPayload.reason_en,
                      reason_ar: event.target.value,
                    });
                  }
                }}
              />
            </Box>
          </Box>

          <Button
            disabled={loading}
            onClick={handleSave}
            sx={{
              "&:hover": { backgroundColor: "#917244" },
              backgroundColor: "#917244",
              width: "200px",
              height: "46px",
              borderRadius: "12px",
              color: "#ffffff",
              fontSize: "14px",
              display: "flex",
              alignSelf: "flex-end",
              marginTop: "5%",
            }}
          >
            Save
          </Button>
        </Box>
      </SecondBox>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Reason has been added Successfully."
        message=""
        buttonText="Go to Rejection Reasons"
        buttonLink="/transactionRejectReasons"
      />
      <ReusableSnackbar />
    </Box>
  );
};
export default AddNewTransactionRejectReason;
