import { Button, Box, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import { useEffect } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import CustomDialog from "../../components/CustomDialog";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import useWindowSize from "../../hooks/useWindowSize";
import CustomTextArea from "../../components/CustomTextArea";
import { useEditTransactionRejectReason } from "../../services/apis/transactionRejectionReasons";

const EditRejectReasons = ({
  open,
  onClose,
  label,
  rejectReasons,
  rejectReasonsId,
  fetchData,
}) => {
  const { width } = useWindowSize();
  const editRejectReason = useEditTransactionRejectReason();
  const [reasonsPayload, setReasonsPayload] = useState({});
  useEffect(() => {
    setReasonsPayload(
      rejectReasons.filter((reason) => reason.id === rejectReasonsId)[0]
    );
  }, [rejectReasons, rejectReasonsId, open]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await editRejectReason.mutateAsync({
        id: rejectReasonsId,
        body: {
          reason_ar: reasonsPayload.reason_ar,
          reason_en: reasonsPayload.reason_en,
        },
      });
      setIsSubmitting(true);
      onClose();
      setSuccessDialogOpen(true);
      await fetchData();
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleReset = () => {
    onClose();
    setReasonsPayload({
      reason_ar: "",
      reason_en: "",
    });
  };
  return (
    <>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Reason has been edit Successfully."
        message=""
        buttonText="Go to Reject Reason"
      />
      <ReusableSnackbar />
      <Dialog
        maxWidth="850px"
        open={open}
        onClose={handleReset}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "24px",
            width: width < 768 ? "100%" : "850px",
          },
        }}
      >
        <DialogContent
          sx={{
            width: width < 768 ? "100%" : "850px",
            height: "auto",
            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            paddingTop: "5%",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              width: "80%",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={onClose}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  color: "#000000",
                  fontWeight: "400",
                  fontSize: "24px",
                  display: "flex",
                  alignSelf: "flex-start",
                }}
              >
                Edit Reason
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#4C4C4C",
                  marginBottom: "5px",
                  marginTop: "15px",
                }}
              >
                Reason - English
              </Typography>
              <CustomTextArea
                style={{
                  minHeight: "54px",
                  maxHeight: "200px",
                  maxWidth: "608px",
                  width: width < 768 ? "100%" : "608px",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "9px",
                  boxShadow: "none",
                  border: "none",
                }}
                name="Reason - English"
                placeholder="eg. New Items"
                value={reasonsPayload?.reason_en}
                onChange={(event) => {
                  let allowedEnglishRegex = /^[a-zA-Z0-9"()@'?:%.,\- ]*$/;
                  if (allowedEnglishRegex.test(event.target.value)) {
                    setReasonsPayload({
                      reason_ar: reasonsPayload.reason_ar,
                      reason_en: event.target.value,
                    });
                  }
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#4C4C4C",
                  marginBottom: "5px",
                  marginTop: "15px",
                }}
              >
                Reason - Arabic
              </Typography>
              <CustomTextArea
                style={{
                  minHeight: "54px",
                  maxHeight: "200px",
                  maxWidth: "608px",
                  width: width < 768 ? "100%" : "608px",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "9px",
                  boxShadow: "none",
                  border: "none",
                }}
                name="Reason - Arabic"
                placeholder="eg. New Items"
                value={reasonsPayload?.reason_ar}
                onChange={(event) => {
                  let allowedArabicRegex = /^[\u0600-\u06FF0-9"()-@'?:%.,\s]*$/;
                  if (allowedArabicRegex.test(event.target.value)) {
                    setReasonsPayload({
                      reason_en: reasonsPayload.reason_en,
                      reason_ar: event.target.value,
                    });
                  }
                }}
              />
            </Box>
            <div className="grid grid-cols-1 md:flex gap-4">
              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={{
                  width: width > 768 ? "200px" : "150px",
                  borderRadius: "9px",
                  background: "#917244",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#917244",
                  },
                }}
                onClick={handleSubmit}
              >
                {label}
              </Button>

              <Button
                variant="outlined"
                sx={{
                  width: width > 768 ? "200px" : "150px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#917244",
                  fontWeight: 600,
                  borderColor: "#917244",
                  "&:hover": {
                    borderColor: "#917244",
                  },
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditRejectReasons;
