import { useQuery } from "react-query";
import { axiosInstance as axios } from "../config";

// export const fetchUsersReports = async () => {
//   try {
//     const response = await axios.get(`/api/reports/users`);
//     return response?.data?.data;
//   } catch (error) {
//     throw error;
//   }
// };
export const useFetchPayments = (q) => {
  return useQuery(["reports-payments"], async () => {
    const response = await axios.get(`/api/reports/payments`);
    return response.data?.data;
  });
};
export const useFetchFractions = (queryParameters) => {
  return useQuery(["reports-fractions", queryParameters], async () => {
    const {
      livePrice_startDate,
      livePrice_endDate,
      distributionOfFraction_startDate,
      distributionOfFraction_endDate,
      buySell_startDate,
      buySell_endDate,
      stock_startDate,
      stock_endDate,
      stockPrice_startDate,
      stockPrice_endDate,
    } = queryParameters;

    const response = await axios.get(`/api/reports/fractions`, {
      params: {
        livePrice_startDate,
        livePrice_endDate,
        distributionOfFraction_startDate,
        distributionOfFraction_endDate,
        buySell_startDate,
        buySell_endDate,
        stock_startDate,
        stock_endDate,
        stockPrice_startDate,
        stockPrice_endDate,
      },
    });

    return response.data?.data;
  });
};
export const useFetchUsersReports = (queryParameters) => {
  const tokenExists = localStorage.getItem("token") !== null;

  return useQuery(
    ["fetch_users_reports", queryParameters],
    async () => {
      const {
        churnRate_startDate,
        churnRate_endDate,
        age_date_from,
        age_date_to,
        cities,
      } = queryParameters;

      const response = await axios.get(`/api/reports/users`, {
        params: {
          churnRate_startDate,
          churnRate_endDate,
          age_date_from,
          age_date_to,
          cities: JSON.stringify(cities),
        },
      });

      return response.data?.data;
    },
    {
      enabled: tokenExists,
    }
  );
};
