import React from "react";
import useUserFilterData from "../../../zustand/useUserFilterData";

const TransactionType = () => {
  const { goldTransactionType, setGoldTransactionType } = useUserFilterData();
  const filterList = [
    { id: "All", name: "All" },
    { id: "Buy", name: "Buy" },
    { id: "Sell", name: "Sell" },
  ];

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setGoldTransactionType([...goldTransactionType, value]);
    } else {
      setGoldTransactionType(
        goldTransactionType.filter((status) => status !== value)
      );
    }
  };

  return (
    <div className="mt-4">
      <p className="text-[#404040] font-medium font-ibmArabic text-lg mb-4">
        Transaction Type
      </p>
      <div className="grid grid-cols-3 gap-4">
        {filterList.map((item) => (
          <div key={item.id} className="flex items-center gap-2">
            <input
              className="text-[#595959] size-4 cursor-pointer"
              type="checkbox"
              id={item.id}
              name="documentStatus"
              value={item.id}
              checked={goldTransactionType.includes(item.id)}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor={item.id} // Fixed to 'htmlFor' for JSX
              className="text-[#595959] font-medium font-ibmArabic text-lg"
            >
              {item.name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TransactionType;
