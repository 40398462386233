import { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import UsersTabPanel from "./UsersTabPanel";
import PhysicalGoldTabPanel from "./PhysicalGoldTabPanel";
import FractionalGoldTabPanel from "./FractionalGoldTabPanel";
import GeneralStatisticsTabPanel from "./GeneralStatisticsTabPanel";
import OrdersTabPanel from "./OrdersTabPanel";
import PaymentTabPanel from "./PaymentTabPanel";
import NewTabs from "../../components/common/NewTabs";
import { useSearchParams } from "react-router-dom";

//~
const Dashboard = () => {
  const tabs = [
    { label: "users", value: "1" },
    { label: "physical gold", value: "2" },
    { label: "fractional gold", value: "3" },
    { label: "general statistics", value: "4" },
    { label: "orders", value: "5" },
    { label: "payment", value: "6" },
  ];
  const [searchParams, setSearchParams] = useSearchParams();
  const initialTab = searchParams.get("tab") || "1";
  const [value, setValue] = useState(initialTab);
  const today = new Date();
  const date14DaysAgo = new Date();
  date14DaysAgo.setDate(today.getDate() - 14);
  useEffect(() => {
    setValue(initialTab);
  }, [initialTab]);
  const handleChange = (newValue) => {
    setValue(newValue);
    setSearchParams({ tab: newValue });
  };

  return (
    <div
      style={{
        overflowY: "auto",
        height: "calc(100vh)",
        backgroundColor: "#f0f0f0",
      }}
    >
      <Container>
        {/* New dashboard  */}
        <DashboardMainHeader />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <NewTabs
            tabs={tabs}
            value={value}
            onChange={handleChange}
            className={"mb-2 bg-[#F5F5F5] rounded-lg"}
          />
        </Box>
        {/* Tab panels */}
        {value === "1" ? (
          <UsersTabPanel />
        ) : value === "2" ? (
          <PhysicalGoldTabPanel />
        ) : value === "3" ? (
          <FractionalGoldTabPanel />
        ) : value === "4" ? (
          <GeneralStatisticsTabPanel />
        ) : value === "5" ? (
          <OrdersTabPanel />
        ) : (
          <PaymentTabPanel />
        )}
      </Container>
    </div>
  );
};

export default Dashboard;
