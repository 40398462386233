import React, { useEffect, useState } from "react";
import { Box, Typography, InputBase, IconButton } from "@mui/material";
import Header from "../../components/Header";
import useFetchAllOrders, {
  useFetchOrderStatus,
  useEditOrder,
} from "../../services/apis/orders";
import { handlePageChange } from "../../services/utils/filters";
import FilterDrawer from "../../components/common/FilterDrawer";
import OrderDetails from "./OrderDetails";
import Popover from "@mui/material/Popover";
import { handleFilterByNumber } from "../../services/utils/filters";
import { TabContext, TabPanel } from "@mui/lab";
import { DataGridStyle } from "../../styles";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import StorageOrderDetails from "./StorageOrderDetails";
import FractionsOrder from "./FractionsOrder";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { FirstBox, SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";
import NewTabs from "../../components/common/NewTabs";
import NewTable from "../../components/common/NewTable";
import { useSearchParams } from "react-router-dom";

const Orders = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialTab = searchParams.get("tab") || "1";
  const [value, setValue] = useState(initialTab);
  const [open, setOpen] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [openDialog3, setOpenDialog3] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [orderDetailsData, setOrderDetailsData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverValue] = useState({});
  const openPop = Boolean(anchorEl);
  const tabs = [
    { label: "delivery orders", value: "1" },
    { label: "storage orders", value: "2" },
    { label: "stored then delivered orders", value: "3" },
    { label: "fractions", value: "4" },
  ];
  const id = openPop ? "simple-popover" : undefined;
  const getOrderType = (value) => {
    switch (value) {
      case "1":
        return [2];
      case "2":
        return [1];
      case "3":
        return [4];
      default:
        return [3];
    }
  };
  const [queryParameters, setQueryParameters] = useState({
    date_from: null,
    date_to: null,
    orderBy: null,
    sort: null,
    page: 0,
    order_type: getOrderType(value),
    orderStatus: "",
    query: null,
  });
  const {
    data,
    isFetching: isLoading,
    refetch: refetchOrders,
  } = useFetchAllOrders(queryParameters);
  const { data: orderStatus, refetch } = useFetchOrderStatus();
  const mutation = useEditOrder();
  const dispatch = useDispatch();

  const handleOpen = (params) => {
    if (params?.row?.order_type === 2 || params?.row?.order_type === 4) {
      setOrderDetails(params?.row?.orderDetails);
      setOrderDetailsData(params?.row);
      setOpen(true);
    } else if (params?.row?.order_type === 1) {
      setOrderDetails(params?.row?.orderDetails);
      setOrderDetailsData(params?.row);
      setOpenDialog2(true);
    } else if (params?.row?.order_type === 3) {
      setOrderDetails(params?.row?.orderDetails);
      setOrderDetailsData(params?.row);
      setOpenDialog3(true);
    }
  };
  const handleClose = () => setOpen(false);
  const handleCloseDialog2 = () => setOpenDialog2(false);
  const handleCloseDialog3 = () => setOpenDialog3(false);

  const handlePopClose = () => {
    setAnchorEl(null);
  };

  const handleChangeOrderStatus = async (id, statusId) => {
    try {
      const body = {
        statusId,
      };
      await mutation.mutateAsync({ id, body }).then(() => {
        refetch();
        refetchOrders(queryParameters);
      });
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
  };
  useEffect(() => {
    setValue(initialTab);
  }, [initialTab]);
  const handleChange = (newValue) => {
    setValue(newValue);
    setSearchParams({ tab: newValue });

    const updatedOrderType = getOrderType(newValue);
    setQueryParameters({
      ...queryParameters,
      date_from: null,
      date_to: null,
      orderBy: null,
      sort: null,
      page: 0,
      order_type: updatedOrderType,
      orderStatus: "",
      q: null,
    });
  };
  const handleSearchInput = (e) => {
    const { value } = e.target;
    setQueryParameters((prevParams) => ({
      ...prevParams,
      query: value.trim(),
    }));
  };

  const handleOrderTypeCheckbox = (e) => {
    handleFilterByNumber(
      e.target.value,
      "order_type",
      queryParameters,
      setQueryParameters
    );
  };

  const handleOrderStatusCheckbox = (e) => {
    handleFilterByNumber(
      e.target.value,
      "orderStatus",
      queryParameters,
      setQueryParameters
    );
  };

  useEffect(() => {
    const updatedQueryParameters = {
      ...queryParameters,
      page: 0,
      orderStatus: "",
      date_from: null,
      date_to: null,
      order_type: getOrderType(value),
      query: null,
    };
    setQueryParameters(updatedQueryParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const ordersColumns = ItemsColumns([
    { field: "id", headerName: "ID" },
    { field: "phone", headerName: "User ID", flex: 2 },
    { field: "created", headerName: "Date", date: true, flex: 2 },
    {
      field: "orderStatus",
      headerName: "Order Status",
      specialRender: "orderStatus",
    },
    {
      field: "address",
      headerName: "Delivery Area",
      specialRender: "customRender",
      renderFunction: (params) => params?.value?.city ?? "",
    },
    { field: "total", specialRender: "local" },
    {
      field: "orderStatusSort",
      headerName: "Change Status",
      flex: 2,
      specialRender: "changeStatus",
      orderStatus: orderStatus,
      orderStatusFunction: (params) => {
        handleChangeOrderStatus(
          params.row.id,
          orderStatus?.data[params.row.orderStatus.sort].id
        );
      },
    },
  ]);
  const storageOrdersColumns = ItemsColumns([
    { field: "id", headerName: "ID" },
    { field: "phone", headerName: "User ID", flex: 2 },
    { field: "created", headerName: "Date", date: true, flex: 2 },
    { field: "totalWeight", headerName: "Total Weight", flex: 2 },
    { field: "storedFees", headerName: "Total Storage Fees", flex: 2 },
    { field: "total", specialRender: "local" },
    {
      field: "orderStatus",
      headerName: "Status",
      specialRender: "orderStatus",
    },
  ]);
  const storedOrdersColumns = ItemsColumns([
    { field: "id", headerName: "ID" },
    { field: "phone", headerName: "User ID", flex: 2 },
    { field: "created", headerName: "Date", date: true, flex: 2 },
    {
      field: "orderStatus",
      headerName: "Order Status",
      specialRender: "orderStatus",
    },
    {
      field: "address",
      headerName: "Delivery Area",
      specialRender: "customRender",
      renderFunction: (params) => params?.value?.city ?? "",
    },
    { field: "deliveryFees", headerName: "Delivery Fees", flex: 2 },
    {
      field: "orderStatusSort",
      headerName: "Change Status",
      flex: 2,
      specialRender: "changeStatus",
      orderStatus: orderStatus,
      orderStatusFunction: (params) => {
        handleChangeOrderStatus(
          params.row.id,
          orderStatus?.data[params.row.orderStatus.sort].id
        );
      },
    },
  ]);
  const fractionsOrdersColumns = ItemsColumns([
    { field: "id", headerName: "ID" },
    { field: "phone", headerName: "User ID", flex: 2 },
    { field: "created", headerName: "Date", date: true, flex: 2 },
    { field: "totalWeight", headerName: "Weight", flex: 2 },
    {
      field: "goldPrice",
      headerName: "Gold Price",
      flex: 1,
      specialRender: "customRender",
      renderFunction: (params) => {
        const goldPrices = params.row.orderDetails.map(
          (detail) => detail.price
        );
        const goldPriceString = goldPrices.join(", ");
        return (
          <Typography>
            {parseFloat(goldPriceString).toLocaleString()}
          </Typography>
        );
      },
    },
    {
      field: "fractionPrice",
      headerName: "Fraction Price",
      flex: 1,
      specialRender: "customRender",
      renderFunction: (params) => {
        const fractionPrices = params.row.orderDetails.map(
          (detail) => detail.total
        );
        const fractionPriceString = fractionPrices.join(", ");
        return (
          <Typography>
            {parseFloat(fractionPriceString)?.toLocaleString()}
          </Typography>
        );
      },
    },
    {
      field: "orderStatus",
      headerName: "Status",
      specialRender: "orderStatus",
    },
  ]);

  return (
    <Box>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Orders" marginL={"20px"} />
      <SecondBox>
        <FirstBox m="40px 0 0 0" height="100%">
          <Box
            m="40px 0 0 0"
            height="auto"
            style={{ backgroundColor: "#ffffff" }}
            sx={{ ...DataGridStyle, overflowX: "auto" }}
          >
            <TabContext value={value}>
              <Box
                sx={{
                  width: "100%",
                  paddingTop: "2%",
                  paddingBottom: "1%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <NewTabs onChange={handleChange} tabs={tabs} value={value} />
              </Box>
              <TabPanel value="1">
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <div className="flex items-center rounded-xl bg-[#f5f5f5] mb-4 p-2 w-[95%] md:w-3/5">
                    <IconButton type="button" aria-label="search">
                      <img
                        alt="search-normal"
                        src={`../../assets/search-normal.png`}
                      />
                    </IconButton>
                    <InputBase
                      onChange={handleSearchInput}
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search Order Id ,National Id ,Phone number or Email ....."
                    />
                    <FilterDrawer
                      queryParameters={queryParameters}
                      setQueryParameters={setQueryParameters}
                      secondTitle="Order Status"
                      secondKey="orderStatus"
                      secondList={orderStatus?.data}
                      handleFirstCheckBox={handleOrderTypeCheckbox}
                      handleSecondCheckBox={handleOrderStatusCheckbox}
                    />
                  </div>
                  <NewTable
                    columns={ordersColumns}
                    isLoading={isLoading}
                    data={data?.data?.result}
                    totalItems={data?.data?.totalItems}
                    totalPages={data?.data?.totalPages}
                    handlePageChange={(newPage) =>
                      handlePageChange(
                        newPage,
                        queryParameters,
                        setQueryParameters
                      )
                    }
                    // const handleCellClick = (params, event) => {
                    //   const { row, field } = params;
                    //   if (field === "username") navigate(`/profile/${row.user.id}`);
                    //   if (field === "totalWeight") {
                    //     setAnchorEl(event.currentTarget);
                    //     setPopoverValue(params.formattedValue);
                    //   }
                    // };
                    // onCellClick={handleCellClick}
                    currentPage={queryParameters}
                  />
                </Box>
              </TabPanel>
              <TabPanel value="2">
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <div className="flex items-center rounded-xl bg-[#f5f5f5] mb-4 p-2 w-[95%] md:w-3/5">
                    <IconButton type="button" aria-label="search">
                      <img
                        alt="search-normal"
                        src={`../../assets/search-normal.png`}
                      />
                    </IconButton>
                    <InputBase
                      onChange={handleSearchInput}
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search Order Id ,National Id ,Phone number or Email ....."
                    />
                    <FilterDrawer
                      queryParameters={queryParameters}
                      setQueryParameters={setQueryParameters}
                      secondTitle="Order Status"
                      secondKey="orderStatus"
                      secondList={orderStatus?.data}
                      handleFirstCheckBox={handleOrderTypeCheckbox}
                      handleSecondCheckBox={handleOrderStatusCheckbox}
                    />
                  </div>
                  <NewTable
                    columns={storageOrdersColumns}
                    isLoading={isLoading}
                    data={data?.data?.result}
                    totalItems={data?.data?.totalItems}
                    totalPages={data?.data?.totalPages}
                    handlePageChange={(newPage) =>
                      handlePageChange(
                        newPage,
                        queryParameters,
                        setQueryParameters
                      )
                    }
                    // const handleCellClick = (params, event) => {
                    //   const { row, field } = params;
                    //   if (field === "username") navigate(`/profile/${row.user.id}`);
                    //   if (field === "totalWeight") {
                    //     setAnchorEl(event.currentTarget);
                    //     setPopoverValue(params.formattedValue);
                    //   }
                    // };
                    // onCellClick={handleCellClick}
                    currentPage={queryParameters}
                  />
                </Box>
              </TabPanel>
              <TabPanel value="3">
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <div className="flex items-center rounded-xl bg-[#f5f5f5] mb-4 p-2 w-[95%] md:w-3/5">
                    <IconButton type="button" aria-label="search">
                      <img
                        alt="search-normal"
                        src={`../../assets/search-normal.png`}
                      />
                    </IconButton>
                    <InputBase
                      onChange={handleSearchInput}
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search Order Id ,National Id ,Phone number or Email ....."
                    />
                    <FilterDrawer
                      queryParameters={queryParameters}
                      setQueryParameters={setQueryParameters}
                      secondTitle="Order Status"
                      secondKey="orderStatus"
                      secondList={orderStatus?.data}
                      handleFirstCheckBox={handleOrderTypeCheckbox}
                      handleSecondCheckBox={handleOrderStatusCheckbox}
                    />
                  </div>
                  <NewTable
                    columns={storedOrdersColumns}
                    isLoading={isLoading}
                    data={data?.data?.result}
                    totalItems={data?.data?.totalItems}
                    totalPages={data?.data?.totalPages}
                    handlePageChange={(newPage) =>
                      handlePageChange(
                        newPage,
                        queryParameters,
                        setQueryParameters
                      )
                    }
                    // const handleCellClick = (params, event) => {
                    //   const { row, field } = params;
                    //   if (field === "username") navigate(`/profile/${row.user.id}`);
                    //   if (field === "totalWeight") {
                    //     setAnchorEl(event.currentTarget);
                    //     setPopoverValue(params.formattedValue);
                    //   }
                    // };
                    // onCellClick={handleCellClick}
                    currentPage={queryParameters}
                  />
                </Box>
              </TabPanel>
              <TabPanel value="4">
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <div className="flex items-center rounded-xl bg-[#f5f5f5] mb-4 p-2 w-[95%] md:w-3/5">
                    <IconButton type="button" aria-label="search">
                      <img
                        alt="search-normal"
                        src={`../../assets/search-normal.png`}
                      />
                    </IconButton>
                    <InputBase
                      onChange={handleSearchInput}
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search Order Id ,National Id ,Phone number or Email ....."
                    />
                    <FilterDrawer
                      queryParameters={queryParameters}
                      setQueryParameters={setQueryParameters}
                      secondTitle="Order Status"
                      secondKey="orderStatus"
                      secondList={orderStatus?.data}
                      handleFirstCheckBox={handleOrderTypeCheckbox}
                      handleSecondCheckBox={handleOrderStatusCheckbox}
                    />
                  </div>
                  <NewTable
                    columns={fractionsOrdersColumns}
                    isLoading={isLoading}
                    data={data?.data?.result}
                    totalItems={data?.data?.totalItems}
                    totalPages={data?.data?.totalPages}
                    handlePageChange={(newPage) =>
                      handlePageChange(
                        newPage,
                        queryParameters,
                        setQueryParameters
                      )
                    }
                    // const handleCellClick = (params, event) => {
                    //   const { row, field } = params;
                    //   if (field === "username") navigate(`/profile/${row.user.id}`);
                    //   if (field === "totalWeight") {
                    //     setAnchorEl(event.currentTarget);
                    //     setPopoverValue(params.formattedValue);
                    //   }
                    // };
                    // onCellClick={handleCellClick}
                    currentPage={queryParameters}
                  />
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        </FirstBox>
      </SecondBox>
      <ReusableSnackbar />
      <Popover
        id={id}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handlePopClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>
          Building: {popoverValue?.building}
        </Typography>
        <Typography sx={{ p: 2 }}>Street: {popoverValue?.street}</Typography>
        <Typography sx={{ p: 2 }}>City: {popoverValue?.city}</Typography>
        <Typography sx={{ p: 2 }}>Town: {popoverValue?.town}</Typography>
      </Popover>
      <OrderDetails
        open={open}
        onClose={handleClose}
        data={orderDetails}
        detials={orderDetailsData}
      />
      <StorageOrderDetails
        open={openDialog2}
        onClose={handleCloseDialog2}
        data={orderDetails}
        detials={orderDetailsData}
      />
      <FractionsOrder
        open={openDialog3}
        onClose={handleCloseDialog3}
        data={orderDetails}
        detials={orderDetailsData}
      />
    </Box>
  );
};

export default Orders;
