import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import { DataGridStyle } from "../../styles";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { formDate } from "../../services/helpers";
import useWindowSize from "../../hooks/useWindowSize";
import { useNavigate } from "react-router-dom";

const FractionTransactionModal = ({ onClose, data, open }) => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/users/${data?.user_id}`);
  };
  return (
    <Dialog
      maxWidth="1000px"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          width: width > 700 ? "900px" : "",
          // width: "900px",
          height: "auto",
          padding: "0 0 2% 0",
          borderRadius: "24px",
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            alignSelf: "flex-end",
            marginTop: "5px",
            float: "right",
          }}
        >
          <IoIosCloseCircleOutline
            onClick={onClose}
            color="#4C4C4C"
            style={{
              width: "24px",
              height: "24px",
              cursor: "pointer",
            }}
          />
        </Box>
        <Box sx={{ marginTop: "5%", marginLeft: "2%", marginBottom: "2%" }}>
          <Typography
            sx={{ fontSize: "24px", fontWeight: "500", color: "#666666" }}
          >
            Transaction Details
          </Typography>
        </Box>
        <Box m="40px 0 0 0" sx={DataGridStyle}>
          <div className="grid  grid-cols-1  md:grid-cols-2 ml-[2%] ">
            <div className="flex items-center">
              <h1 className="text-[#595959] text-sm font-medium font-ibmArabic">
                Transaction ID :
              </h1>
              <h1 className="text-[#595959] text-sm font-medium ml-2 font-ibmArabic">
                {" "}
                {data?.id}
              </h1>
            </div>
            <div className="flex items-center">
              <h1 className="text-[#595959] text-sm font-medium font-ibmArabic">
                User name :
              </h1>
              <h1
                onClick={handleClick}
                className="text-[#917244] text-sm font-medium ml-2 underline cursor-pointer font-ibmArabic"
              >
                {" "}
                {data?.user_full_name}
              </h1>
            </div>
          </div>
          <hr className="text-[#e6e6e6] border-t-[1px]  w-[90%] my-3 ml-[2%]" />
          <div className="grid  grid-cols-1  md:grid-cols-2 ml-[2%] ">
            <div className="flex items-center">
              <h1 className="text-[#595959] text-sm font-normal font-ibmArabic">
                User Phone number :
              </h1>
              <h1 className="text-[#595959] text-sm font-normal ml-2 font-ibmArabic">
                {" "}
                0{data?.user_phone}
              </h1>
            </div>
            <div className="flex items-center">
              <h1 className="text-[#595959] text-sm font-normal font-ibmArabic">
                Transaction Date:
              </h1>
              <h1 className="text-[#595959] text-sm font-normal ml-2 font-ibmArabic">
                {" "}
                {formDate(data?.created)}
              </h1>
            </div>
          </div>
          <hr className="text-[#e6e6e6] border-t-[1px]  w-[90%] my-3 ml-[2%]" />
          <div className="grid  grid-cols-1  md:grid-cols-2 ml-[2%] ">
            <div className="flex items-center">
              <h1 className="text-[#595959] text-sm font-normal font-ibmArabic">
                Weight :
              </h1>
              <h1 className="text-[#595959] text-sm font-normal ml-2 font-ibmArabic">
                {" "}
                {Math.abs(data?.total_weight || 0)} Gram
              </h1>
            </div>
            <div className="flex items-center">
              <h1 className="text-[#595959] text-sm font-normal font-ibmArabic">
                Transaction Type :
              </h1>
              <h1
                className={` text-sm font-normal ml-2  font-ibmArabic   border-l-2 px-2  ${
                  data?.goldTransactionType == "1"
                    ? "border-[#28A745] text-[#28A745]"
                    : "border-[#DC3545] text-[#DC3545]"
                }`}
              >
                {" "}
                {data?.goldTransactionType == "1" ? "Buy" : "Sell"}
              </h1>
            </div>
          </div>
          <hr className="text-[#e6e6e6] border-t-[1px]  w-[90%] my-3 ml-[2%]" />
          <div className="grid  grid-cols-1  md:grid-cols-2 ml-[2%] ">
            <div className="flex items-center">
              <h1 className="text-[#595959] text-sm font-normal font-ibmArabic">
                Gold Price :
              </h1>
              <h1 className="text-[#595959] text-sm font-normal ml-2 font-ibmArabic">
                {" "}
                {data?.price?.toLocaleString()} EGP
              </h1>
            </div>
            <div className="flex items-center">
              <h1 className="text-[#595959] text-sm font-normal font-ibmArabic">
                Value of Gold :
              </h1>
              <h1 className="text-[#595959] text-sm font-normal ml-2  font-ibmArabic">
                {" "}
                {Number(data?.valueOfGold).toLocaleString("en-US")}
              </h1>
            </div>
          </div>
          {data?.goldTransactionType == "1" && (
            <>
              <hr className="text-[#e6e6e6] border-t-[1px]  w-[90%] my-3 ml-[2%]" />
              <div className="grid  grid-cols-1  md:grid-cols-2 ml-[2%] ">
                <div className="flex items-center">
                  <h1 className="text-[#595959] text-sm font-normal font-ibmArabic">
                    Manufacturing Fee :
                  </h1>
                  <h1 className="text-[#595959] text-sm font-normal ml-2 font-ibmArabic">
                    {data?.manufacturing_fees?.toLocaleString()} EGP
                  </h1>
                </div>
                <div className="flex items-center">
                  <h1 className="text-[#595959] text-sm font-normal font-ibmArabic">
                    Taxes :
                  </h1>
                  <h1 className="text-[#595959] text-sm font-normal ml-2  font-ibmArabic">
                    {data?.taxes?.toLocaleString()} EGP
                  </h1>
                </div>
              </div>
            </>
          )}

          <hr className="text-[#e6e6e6] border-t-[1px]  w-[90%] my-3 ml-[2%]" />
          <div className="grid  grid-cols-1  md:grid-cols-2 ml-[2%] ">
            <div className="flex items-center">
              <h1 className="text-[#595959] text-sm font-normal font-ibmArabic">
                Transaction Fee :
              </h1>
              <h1 className="text-[#595959] text-sm font-normal ml-2 font-ibmArabic">
                {" "}
                {data?.transaction_fees} EGP
              </h1>
            </div>
            {data?.goldTransactionType == "1" ? (
              <div className="flex items-center">
                <h1 className="text-[#595959] text-sm font-normal font-ibmArabic">
                  Storage Fee :
                </h1>
                <h1 className="text-[#595959] text-sm font-normal ml-2 font-ibmArabic">
                  {data?.totalStoreFees} EGP
                </h1>
              </div>
            ) : (
              <div className="flex items-center">
                <h1 className="text-[#595959] text-sm font-normal font-ibmArabic">
                  Cashback :
                </h1>
                <h1 className="text-[#595959] text-sm font-normal ml-2 font-ibmArabic">
                  {data?.cashback} EGP
                </h1>
              </div>
            )}
          </div>
          {data?.goldTransactionType == "1" && (
            <>
              <hr className="text-[#e6e6e6] border-t-[1px]  w-[90%] my-3 ml-[2%]" />
              <div className="grid  grid-cols-1  md:grid-cols-2 ml-[2%] ">
                <div className="flex items-center">
                  <h1 className="text-[#595959] text-sm font-normal font-ibmArabic">
                    Voucher Code :
                  </h1>
                  <h1 className="text-[#595959] text-sm font-normal ml-2 font-ibmArabic">
                    {data?.code || ""}
                  </h1>
                </div>
                {/* <div className="flex items-center">
                  <h1 className="text-[#595959] text-sm font-normal font-ibmArabic">
                    Discount Value :
                  </h1>
                  <h1 className="text-[#595959] text-sm font-normal ml-2  font-ibmArabic">
                    {data?.valueOfGold}
                  </h1>
                </div> */}
              </div>
            </>
          )}

          {data?.goldTransactionType == "2" && (
            <>
              <hr className="text-[#e6e6e6] border-t-[1px]  w-[90%] my-3 ml-[2%]" />
              <div className="grid  grid-cols-1  md:grid-cols-2 ml-[2%] ">
                <div className="flex items-center">
                  <h1 className="text-[#595959] text-sm font-normal font-ibmArabic">
                    Total Received by User :
                  </h1>
                  <h1 className="text-[#595959] text-sm font-normal ml-2 font-ibmArabic">
                    {Math.abs(data?.total || 0)?.toLocaleString()} EGP
                  </h1>
                </div>
              </div>
            </>
          )}
          {data?.goldTransactionType == "1" && (
            <>
              <hr className="text-[#e6e6e6] border-t-[1px]  w-[90%] my-3 ml-[2%]" />
              <div className="grid  grid-cols-1  md:grid-cols-2 ml-[2%] ">
                <div className="flex items-center">
                  <h1 className="text-[#595959] text-sm font-normal font-ibmArabic">
                    Order Total:
                  </h1>
                  <h1 className="text-[#595959] text-sm font-normal ml-2 font-ibmArabic">
                    {Math.abs(data?.total || 0)?.toLocaleString()} EGP
                  </h1>
                </div>
              </div>
            </>
          )}
          <div className="grid ml-[2%] my-3 mt-6">
            <div className="">
              <h1 className="text-[#595959] text-sm font-normal font-ibmArabic">
                Serial number :
              </h1>{" "}
              <div className="flex flex-wrap gap-[4px] mt-2">
                {data?.serials
                  ?.concat(data?.sold_serials || [])
                  .map((serial, index) => (
                    <div
                      key={index}
                      className="m-[2px] rounded-[100px] bg-[#F5F5F5] px-[6px] py-[10px]"
                    >
                      <h1 className="text-[#595959] text-sm font-normal ml-2 font-ibmArabic">
                        {serial}
                      </h1>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          {/* <TableContainer>
            <Table sx={{ borderCollapse: "collapse" }}>
              <TableHead sx={{}}>
                <TableRow>
                  <TableCell
                    style={{
                      textAlign: "center",
                      color: "#4C4C4C",
                      fontWeight: "600",
                    }}
                  >
                    ID
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      color: "#4C4C4C",
                      fontWeight: "600",
                    }}
                  >
                    User Phone number
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Gold Price
                  </TableCell>
                 
                  <TableCell
                    style={{
                      textAlign: "center",
                      color: "#4C4C4C",
                      fontWeight: "600",
                    }}
                  >
                    Price
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      color: "#4C4C4C",
                      fontWeight: "600",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      color: "#4C4C4C",
                      fontWeight: "600",
                    }}
                  >
                    Serial No.
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.serials
                  ?.concat(data?.sold_serials || [])
                  .map((serial, index) => (
                    <TableRow key={index} style={{ cursor: "pointer" }}>
                      <TableCell
                        sx={cellsStyle}
                        style={{ textAlign: "center" }}
                      >
                        {data.id}
                      </TableCell>
                      <TableCell
                        sx={cellsStyle}
                        style={{ textAlign: "center" }}
                      >
                        {data?.user_phone}
                      </TableCell>
                      <TableCell
                        sx={cellsStyle}
                        style={{ textAlign: "center" }}
                      >
                        {data?.price} EGP
                      </TableCell>
                      
                      <TableCell
                        sx={cellsStyle}
                        style={{ textAlign: "center" }}
                      >
                        {Math.abs(parseFloat(data.total))?.toLocaleString()} EGP
                      </TableCell>
                      <TableCell
                        sx={cellsStyle}
                        style={{ textAlign: "center" }}
                      >
                        {formDate(data?.created)}
                      </TableCell>
                      <TableCell
                        sx={cellsStyle}
                        style={{ textAlign: "center" }}
                      >
                        {serial}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer> */}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FractionTransactionModal;
