import { Box, Button, Typography } from "@mui/material";
import newEye from "../../svg/neweye.svg";
import { DeleteSvgWithFunc, EditSvgWithFunc } from "./SvgWithFunc";
import {
  formatDateWithoutChangingTime,
  formDate,
} from "../../services/helpers";
import { capitalizeFirstLetter } from "../../services/utils/stylesModifiers";

export const ItemsColumns = (columnsConfig) => {
  const defaultFlex = 1;
  const defaultHeaderAlign = "center";
  const defaultAlign = "center";
  const defaultHeaderClassName = "bold-header";
  const columns = columnsConfig.map((column) => {
    let renderCell;
    if (column.add) {
      renderCell = (params) =>
        `${params.row[column.field] || ""} ${column.add}`;
    } else if (column.specialRender === "availability") {
      renderCell = (params) => {
        const availability = params.row.availability;
        let color = "";
        let background = "";
        let circle = "";

        if (availability === 0) {
          color = "#CF2424";
          background = "#F230450D";
          circle = "#CF2424";
        } else if (availability === 1) {
          color = "#24CF80";
          background = "#40C7540D";
          circle = "#24CF80";
        }

        return (
          <div
            className="flex gap-3 rounded-[100px] items-center justify-center p-2"
            style={{
              background,
              color,
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="8"
              viewBox="0 0 9 8"
              fill="none"
            >
              <circle cx="4.22852" cy="4" r="4" fill={circle} />
            </svg>
            <p sx={{ color }}>
              {availability === 0 ? "Out Of Stock" : "In Stock"}
            </p>
          </div>
        );
      };
    } else if (column.specialRender === "orderHistory") {
      renderCell = (params) => {
        const orderStatus = params.row.status;
        let color = "";
        let circle = "";
        let background = "";
        if (orderStatus === 0) {
          color = "#17A2B8";
          circle = "#17A2B8";
          background = "#17A2B80D";
        } else if (orderStatus === 1) {
          color = "#28A745";
          circle = "#28A745";
          background = "#28A7450D";
        } else {
          color = "#DC3545";
          circle = "#DC3545";
          background = "#DC35450D";
        }
        return (
          <div
            className={`flex items-center justify-center rounded-[100px] gap-2  p-2 w-full`}
            style={{
              background,
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="8"
              viewBox="0 0 9 8"
              fill="none"
            >
              <circle cx="4.22852" cy="4" r="4" fill={circle} />
            </svg>
            <p style={{ color }}>
              {orderStatus === 0
                ? "Processing"
                : orderStatus === 1
                ? "Delivered"
                : "Canceled"}
            </p>
          </div>
        );
      };
    } else if (column.specialRender === "customView") {
      renderCell = (params) => {
        const transaction = params.row;
        const shouldRenderIcon =
          transaction.type === "Withdraw" ||
          (transaction.type === "Deposit" &&
            transaction.paymentMethod !== "paymob");

        return (
          shouldRenderIcon && (
            <img
              style={{ cursor: "pointer" }}
              src={newEye}
              alt="eye"
              onClick={() => column.actions.view(params)}
            />
          )
        );
      };
    } else if (column.specialRender === "paymentMethods") {
      renderCell = (params) => {
        let value;
        if (params.row.paymentMethod === null) {
          value = "Wallet";
        } else {
          value = params.row.paymentMethod;
        }
        return value;
      };
    } else if (column.specialRender === "totalWeight") {
      renderCell = (params) => {
        const value = params?.row[column.field];
        if (value == null) return <span>-</span>; // Handle undefined or null

        let formattedValue;
        if (Number.isInteger(value)) {
          formattedValue = Math.abs(value);
        } else {
          const absValue = Math.abs(value);
          formattedValue = absValue.toFixed(3).replace(/\.?0+$/, "");
        }
        return <span>{formattedValue}</span>;
      };
    } else if (column.specialRender === "absLocal") {
      renderCell = (params) => {
        const value = params?.row[column.field];
        return <span>{Math.abs(value).toLocaleString()}</span>;
      };
    } else if (column.specialRender === "local") {
      renderCell = (params) => {
        const value = params?.row[column.field];
        return <span>{value.toLocaleString()}</span>;
      };
    } else if (column.specialRender === "amount") {
      renderCell = (params) => {
        const value = params?.row[column.field];
        const formattedValue = Number(value).toLocaleString();

        return <span>{formattedValue}</span>;
      };
    } else if (column.specialRender === "abs") {
      renderCell = (params) => {
        const value = params?.value;
        return <span>{Math.abs(value)}</span>;
      };
    } else if (column.specialRender === "goldTransactionType") {
      renderCell = (params) => {
        let transactionTypeLabel;
        let color;
        switch (params?.row[column.field]) {
          case 1:
            transactionTypeLabel = "buy";
            color = "#40C754";
            break;
          case 2:
            transactionTypeLabel = "sell";
            color = "#F23045";
            break;
          default:
            transactionTypeLabel = "Unknown";
            color = "#000"; // Default color for unknown types
        }
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
              color,
              borderLeft: `3px solid ${color}`,
              // paddingLeft: "4px",
            }}
          >
            <Typography>
              {capitalizeFirstLetter(transactionTypeLabel)}
            </Typography>
          </Box>
        );
      };
    } else if (column.specialRender === "serials") {
      renderCell = (params) => {
        const { row } = params;
        const combinedSerials = [
          ...(params?.row?.[column.field] || []),
          ...(row.sold_serials || []),
        ];
        if (Array.isArray(combinedSerials)) {
          if (combinedSerials.length > 1) {
            return (
              <Typography
                variant="body2"
                color="#917244"
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => column.serialsFunction(params)}
              >
                See more
              </Typography>
            );
          } else if (combinedSerials.length === 1) {
            if (params.row.goldTransactionType === 2) {
              return (
                <span>
                  {/* {`00-${combinedSerials[0]}-${params.row.id}-00000-0${
                    Math.abs(params.row?.total_weight) * 1000
                  }`} */}
                  {combinedSerials[0]}
                </span>
              );
            } else {
              return (
                <span>
                  {combinedSerials[0]}
                  {/* {`00-${combinedSerials[0]}-${params.row.id}-0${
                    Math.abs(params.row?.total_weight) * 1000
                  }-00000`} */}
                </span>
              );
            }
          }
        }
        return null;
      };
    } else if (column.specialRender === "orderStatus") {
      renderCell = (params) => {
        const status = params.row.orderStatus;
        const statusName = status?.name;
        let color = "";
        switch (statusName) {
          case "New order":
            color = "#CF2424";
            break;
          case "In Progress":
            color = "#ECB800";
            break;
          case "Out for Delivery":
            color = "#24CF80";
            break;
          case "Deliverd":
            color = "#2492CF";
            break;
          case "Completed":
            color = "#00303C";
            break;
          case "canceled":
            color = "#000000";
            break;
          default:
            color = "#000000"; // Default color for unknown statuses
        }
        return (
          <p
            className=" "
            style={{
              color: color,
            }}
          >
            {statusName}
          </p>
        );
      };
    } else if (column.specialRender === "customRender") {
      renderCell = (params) => column.renderFunction(params);
    } else if (column.specialRender === "changeStatus") {
      renderCell = (params) => {
        const status = params.row.orderStatus;
        const statusName = status?.name;
        let color = "";

        if (statusName === "canceled") {
          return (
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                color: "#000000",
              }}
            >
              Canceled
            </Typography>
          );
        } else if (statusName === "Completed") {
          return (
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                color: "#00303C",
              }}
            >
              Completed
            </Typography>
          );
        } else {
          const statusId = status?.sort;
          if (column?.orderStatus?.data[statusId]?.name === "In Progress")
            color = "#ECB800";
          if (column?.orderStatus?.data[statusId]?.name === "Out for Delivery")
            color = "#24CF80";
          if (column?.orderStatus?.data[statusId]?.name === "Deliverd")
            color = "#2492CF";

          return column?.orderStatus?.data[statusId]?.name &&
            params.row.order_type !== 3 ? (
            <Button
              variant="outlined"
              sx={{
                border: `1px solid ${color}`,
                width: "150px",
                height: "33px",
                color: color,
                borderRadius: "100px",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 500,
              }}
              onClick={() => {
                column?.orderStatusFunction(params);
              }}
            >
              {column?.orderStatus?.data[statusId]?.name}
            </Button>
          ) : (
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                color: "#00303C",
              }}
            >
              Completed
            </Typography>
          );
        }
      };
    } else if (column.specialRender === "permissions") {
      renderCell = (params) => {
        const permissions = params.row.permissions;
        const permissionElements = permissions.map((permission) => (
          <div
            className="m-[2px] rounded-[100px] bg-[#F5F6FF] px-[6px] py-[10px]"
            key={permission.id}
          >
            {permission.name}
          </div>
        ));

        return (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              {permissionElements}
            </div>
            {params?.row?.permissionsCount !== 0 && (
              <div>+ {params?.row?.permissionsCount} More</div>
            )}
          </>
        );
      };
    } else if (column.actions) {
      renderCell = (params) => (
        <div className="flex gap-3 justify-center items-center mx-auto">
          {column.actions.view && (
            <img
              style={{ cursor: "pointer" }}
              src={newEye}
              alt="view"
              onClick={() => column.actions.view(params)}
            />
          )}
          {column.actions.edit && (
            <EditSvgWithFunc onClick={() => column.actions.edit(params)} />
          )}
          {column.actions.delete && (
            <DeleteSvgWithFunc onClick={() => column.actions.delete(params)} />
          )}
        </div>
      );
    } else if (column.float) {
      renderCell = (params) => {
        const value = parseFloat(params.row[column.field]);
        return !isNaN(value) ? value.toLocaleString() : "0";
      };
    } else if (column.isOnline) {
      renderCell = (params) => {
        return (
          <div
            className={`
              border-l-2 px-2    
              text-sm
              ${
                params?.row?.is_online === 0
                  ? "text-[#cf2424] border-[#cf2424]"
                  : "text-[#28A745] border-[#28A745]"
              }`}
          >
            {params.row.is_online === 0 ? "Offline" : "Online"}
          </div>
        );
      };
    } else if (column.documentStatus) {
      renderCell = (params) => {
        const status = params?.row?.status_id
          ? params?.row?.status_id
          : params?.row?.kyc_status;
        return (
          <div
            className={`
              border-l-2 px-2   
              text-sm
               ${
                 status === "approved" || status === "Accepted"
                   ? "text-[#28A745] border-[#28A745]"
                   : status === "rejected" || status === "Rejected"
                   ? "text-[#DC3545] border-[#DC3545]"
                   : status === "pending" || status === "Pending"
                   ? "text-[#FFC107] border-[#FFC107]"
                   : "text-[#595959] border-[#595959]"
               } `}
          >
            {capitalizeFirstLetter(
              status === "No KYC" ? "No Document" : status
            )}
          </div>
        );
      };
    } else if (column.date) {
      renderCell = (params) => formDate(params.row[column.field]);
    } else if (column.dateWithoutChangingTime) {
      renderCell = (params) =>
        formatDateWithoutChangingTime(params.row[column.field]);
    } else if (column.kyc) {
      renderCell = (params) => {
        const status = params?.row?.status_id
          ? params?.row?.status_id
          : params?.row?.kyc_status;
        return (
          <div
            className={`flex items-center justify-center gap-[0.4375rem] px-4 py-2 rounded-full bg-opacity-5
             ${
               status === "approved" || status === "Accepted"
                 ? "bg-[#40c754]"
                 : status === "rejected" || status === "Rejected"
                 ? "bg-[#f23045]"
                 : status === "pending" || status === "Pending"
                 ? "bg-[#ecb800] "
                 : "bg-[#4c4c4c]"
             } 
              
              `}
          >
            <div
              className={`w-[0.375rem] h-[0.375rem] rounded-full 
               ${
                 status === "approved" || status === "Accepted"
                   ? "bg-[#30D158]"
                   : status === "rejected" || status === "Rejected"
                   ? "bg-[#ff453a]"
                   : status === "pending" || status === "Pending"
                   ? "bg-[#ECB800]"
                   : "bg-[#4c4c4c]"
               } 
              `}
            ></div>
            <p
              className={`text-xs 
              ${
                status === "approved" || status === "Accepted"
                  ? "text-[#30D158]"
                  : status === "rejected" || status === "Rejected"
                  ? "text-[#ff453a]"
                  : status === "pending" || status === "Pending"
                  ? "text-[#ECB800]"
                  : "text-[#4c4c4c]"
              } 
              `}
            >
              {capitalizeFirstLetter(status)}
            </p>
          </div>
        );
      };
    } else {
      renderCell =
        ((params) => {
          return params.row[column.field];
        }) || column.renderCell;
    }

    return {
      headerClassName: column.headerClassName || defaultHeaderClassName,
      field: column.field,
      headerName: column?.headerName
        ? column.headerName
        : capitalizeFirstLetter(column.field),
      flex: column.flex || defaultFlex,
      headerAlign: column.headerAlign || defaultHeaderAlign,
      align: column.align || defaultAlign,
      renderCell,
    };
  });

  return columns;
};
