import React, { useRef } from "react";
import { Button, Box, Typography, FormControl, Avatar } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import TextFieldComponent from "../../../components/TextFieldComponent";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useSubmitUpdateStatus } from "../../../services/apis/inventory";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";
import mark from "../../../svg/mark.svg";

const ChangeOrderStatus = ({
  open,
  onClose,
  label,
  refetch,
  selectedRowId,
  getRequestSerials,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [requestsPayload, setRequestsPayload] = useState({
    serial: null,
  });
  const { mutate } = useSubmitUpdateStatus();
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [inputKey, setInputKey] = useState(0);
  const [hasChanges, setHasChanges] = useState(false);
  const handleDeleteImage = () => {
    setImage(null);
    setFile(null);
  };

  const resetForm = () => {
    setRequestsPayload({
      serial: null,
    });
    if (hasChanges) {
      setImage(null);
      setFile(null);
      setHasChanges(false);
    }
    onClose();
  };
  const handleCloseDialog = () => {
    resetForm();
  };
  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };
  const handleFileInputChange = (event) => {
    if (event && event.target) {
      const selectedFile = event.target.files[0];
      if (selectedFile && selectedFile instanceof File) {
        setFile(selectedFile);
        const reader = new FileReader();
        reader.onload = (e) => {
          setImage(e.target.result);
        };
        reader.readAsDataURL(selectedFile);
        setRequestsPayload((prevPayload) => ({
          ...prevPayload,
          photo: selectedFile,
        }));
        setInputKey(inputKey + 1);
      }
    }
  };
  const fileInputRef = useRef(null);
  // edit image start
  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      setHasChanges(true);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setFile(file);

    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleSubmit = () => {
    if (requestsPayload.serial && file) {
      const payload = {
        serial: requestsPayload.serial,
        availability: 1,
        image: file,
      };
      setIsSubmitting(true);
      mutate(
        {
          id: selectedRowId,
          body: payload,
        },
        {
          onSuccess: () => {
            handleCloseDialog();
            setSuccessDialogOpen(true);
            refetch();
            getRequestSerials();
            setIsSubmitting(false);
          },
          onError: (error) => {
            dispatch(
              setData({
                openSnack: true,
                message: "An error occurred while submitting the order",
              })
            );
            setIsSubmitting(false);
          },
        }
      );
    } else {
      dispatch(
        setData({
          openSnack: true,
          message: "Please Fill in the empty fields",
        })
      );
    }
  };
  return (
    <>
      <Dialog
        open={successDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            padding: { xs: "16px", sm: "40px" },
            height: { xs: "auto", sm: "506px" },
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            sx={{
              bgcolor: "#D9F4DD",
              height: "80px",
              width: "80px",
              alignSelf: "center",
              marginRight: "15px",
            }}
          >
            <img src={`../../assets/Vector.png`} alt="vector" />
          </Avatar>
          <Typography
            sx={{ marginTop: "10%", marginBottom: "10%" }}
            variant="h6"
          >
            Order Status Changed Successfully.
          </Typography>
          <Button
            onClick={() => {
              handleSuccessDialogClose();
            }}
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#917244",
              "&:hover": { backgroundColor: "#917244" },
              height: "46px",
              width: "291px",
              borderRadius: "9px",
            }}
          >
            Go to order Details
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            padding: { xs: "16px", sm: "40px" },
            height: "auto",
            borderRadius: "24px",
            width: "100%",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            paddingTop: "5%",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              width: "80%",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={onClose}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box
              sx={{
                width: "80px",
                height: "80px",
                borderRadius: "50px",
              }}
            >
              <img src={mark} alt="mark" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  color: "#595959",
                  fontWeight: "400",
                  fontSize: "18px",
                  display: "flex",
                  alignSelf: "center",
                }}
              >
                Make this ingot In stock ?
              </Box>
            </Box>
            <FormControl
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "5px",
                  marginTop: "15px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      color: "#595959",
                      marginBottom: "5px",
                    }}
                  >
                    Ingot image
                  </Typography>
                  {!image && (
                    <div
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      className="mt-2 w-full h-[53px] border-2 border-dashed border-gray-300 flex items-center justify-center rounded-xl bg-gray-100 mb-2"
                    >
                      <img src={`../../assets/export.png`} alt="" />
                      <Box sx={{ display: "flex" }}>
                        <input
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          ref={fileInputRef}
                          onChange={handleFileInputChange}
                        />
                        {!image && (
                          <Button
                            sx={{
                              color: "#917244",
                              textDecoration: "underLine",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                            onClick={handleUploadButtonClick}
                          >
                            click to upload
                          </Button>
                        )}
                        {image ? (
                          <img
                            src={image}
                            alt="Dropped"
                            height="152px"
                            width="100%"
                          />
                        ) : (
                          <p
                            style={{
                              color: "#808080",
                              fontSize: "12px",
                              marginTop: "9px",
                            }}
                          >
                            or drag and drop{"  "}
                          </p>
                        )}
                      </Box>
                      <Typography
                        sx={{
                          color: "#CCCCCC",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontFamily: "Roboto",
                          marginLeft: "5px",
                        }}
                      >
                        PNG or JPG (max. 3MB)
                      </Typography>
                    </div>
                  )}
                </Box>
                {file && (
                  <Box
                    sx={{
                      width: "100%",
                      borderRadius: "9px",
                      background: "#FFF",
                      boxShadow: "1px 1px 16px 0px rgba(0, 51, 102, 0.08)",
                      display: "flex",
                      height: "53px",
                      padding: "16px",
                      justifyContent: "space-between",
                      marginTop: "5px",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <img src={`../../assets/uploadicon.png`} alt="upload" />
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                          }}
                        >
                          {file.name}
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(0, 0, 0, 0.60)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            letterSpacing: "0.17px",
                            wordSpacing: "4px",
                          }}
                        >
                          {Math.ceil(file.size / 1024)}kb • Complete
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ display: "flex" }}>
                      <Avatar
                        sx={{
                          bgcolor: "#D9F4DD",
                          height: "24px",
                          width: "24px",
                          alignSelf: "center",
                          marginRight: "15px",
                        }}
                      >
                        <img
                          src={`../../assets/tick-circle.png`}
                          alt="circle"
                        />
                      </Avatar>

                      <Avatar
                        onClick={handleDeleteImage}
                        sx={{
                          cursor: "pointer",
                          // height: "24px",
                          // width: "24px",
                          alignSelf: "center",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <img src={`../../assets/delete.png`} alt="delete" />
                      </Avatar>
                    </Box>
                  </Box>
                )}
              </Box>
              <TextFieldComponent
                sx={{ "& fieldset": { border: "none" }, color: "#595959" }}
                style={{
                  fontSize: "14px",
                  color: "#595959",
                  height: "48px",
                  backgroundColor: "transparent",
                  border: "1px solid #E6E6E6",
                  borderRadius: "12px",
                }}
                name="Enter Ingot Serial"
                placeholder="Enter Enter Ingot Serial"
                value={requestsPayload.serial || ""}
                onChange={(event) => {
                  const allowedCharacters = event.target.value.replace(
                    /[^a-zA-Z0-9.\-_ ]/g,
                    ""
                  ); // Include space in the allowed characters
                  const numericValue = event.target.value.replace(/\D/g, "");
                  setRequestsPayload({
                    ...requestsPayload,
                    serial: allowedCharacters,
                  });
                }}
                inputProps={{
                  inputMode: "text",
                  pattern: "[a-zA-Z0-9._\\- ]*",
                }}
              />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexDirection: { xs: "column", sm: "row" },
                marginTop: "10px",
              }}
            >
              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  background: "#917244",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#917244",
                  },
                }}
                onClick={handleSubmit}
              >
                {label}
              </Button>

              <Button
                variant="outlined"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#917244",
                  fontWeight: 600,
                  borderColor: "#917244",
                  "&:hover": {
                    borderColor: "#917244",
                  },
                }}
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <ReusableSnackbar />
    </>
  );
};

export default ChangeOrderStatus;
