import { Box, Button, Step, StepLabel, Stepper } from "@mui/material";
import React from "react";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import { useState } from "react";
import StepOneFaqComponent from "./StepOneFaqComponent";
import StepTwoFaqComponent from "./StepTwoFaqComponent";
import { addQuestion } from "../../services/apis/faq";
import CustomDialog from "../../components/CustomDialog";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { useStylesHook } from "../../components/common/useStyles";
import { stepsEA } from "../../data/steps";

// Arabic letters and spaces regex validation
// Arabic letters and spaces regex validation
const isArabic = (text) =>
  /^[\u0600-\u06FF\s\d!@#$%^&*()_+={}\[\]:;"'<>,.?/-]*$/.test(text); // Includes Arabic letters, spaces, numbers, and special characters

const isEnglish = (text) =>
  /^[A-Za-z\s\d!@#$%^&*()_+={}\[\]:;"'<>,.?/-]*$/.test(text); // Includes English letters, spaces, numbers, and special characters

const AddNewFAQ = () => {
  const c = useStylesHook();
  const [activeStep, setActiveStep] = useState(0);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [faqData, setFaqData] = useState({
    is_popular: null,
    trans: [
      {
        local: "en",
        title: "",
        description: "",
      },
      {
        local: "ar",
        title: "",
        description: "",
      },
      {
        local: "eg",
        title: ".",
        description: ".",
      },
    ],
  });
  const dispatch = useDispatch();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleSave = async () => {
    try {
      setLoading(true);
      await addQuestion(faqData);
      setSuccessDialogOpen(true);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <StepOneFaqComponent
            faqData={faqData}
            setFaqData={setFaqData}
            isEnglish={isEnglish}
          />
        );
      case 1:
        return (
          <StepTwoFaqComponent
            faqData={faqData}
            setFaqData={setFaqData}
            isArabic={isArabic}
          />
        );
      // case 2:
      //   return (
      //     <StepThreeFaqComponent faqData={faqData} setFaqData={setFaqData} />
      //   );
      default:
        return null;
    }
  };
  return (
    <Box>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <Box sx={{ mb: "3%" }}>
          <DashboardMainHeader />
        </Box>
        <Header title="Add FAQ" />
      </Box>
      <Box
        m="20px"
        sx={{
          height: "70vh",
          background: "#FFFFFF",
          borderRadius: "12px",
          boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08)",
          overflow: "auto",
          padding: "4%",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Stepper className={c.root} activeStep={activeStep}>
            {stepsEA.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <>
            {renderStepContent(activeStep)}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              {activeStep !== 0 && (
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#ffffff",
                    },
                    backgroundColor: "#ffffff",
                    width: "200px",
                    height: "46px",
                    borderRadius: "12px",
                    color: "#917244",
                    border: "3px solid #917244",
                    fontSize: "14px",
                    marginRight: "20px",
                  }}
                >
                  Back
                </Button>
              )}
              <Button
                disabled={
                  (activeStep === 0
                    ? !faqData.trans[0]?.title ||
                      !faqData.trans[0]?.description ||
                      faqData.is_popular == null
                    : activeStep === 1
                    ? !faqData.trans[1]?.title || !faqData.trans[1]?.description
                    : !faqData.trans[2]?.title ||
                      !faqData.trans[2]?.description) || loading
                }
                sx={{
                  "&:hover": { backgroundColor: "#917244" },
                  backgroundColor: "#917244",
                  width: "200px",
                  height: "46px",
                  borderRadius: "12px",
                  color: "#ffffff",

                  fontSize: "14px",
                }}
                onClick={
                  activeStep === stepsEA.length - 1 ? handleSave : handleNext
                }
              >
                {activeStep === stepsEA.length - 1 ? "Save" : "Next"}
              </Button>
            </Box>
          </>
        </Box>
      </Box>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Question has been added Successfully."
        message=""
        buttonText="Go to FAQ"
        buttonLink="/faq"
      />
      <ReusableSnackbar />
    </Box>
  );
};

export default AddNewFAQ;
