import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DataGridStyle } from "../../styles";
import Header from "../../components/Header";
import { useFetchProductsInfo } from "../../services/apis/products";
import { handlePageChange } from "../../services/utils/filters";
import ProductModal from "./ProductModal";
import { TabContext, TabPanel } from "@mui/lab";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { useNavigate, useSearchParams } from "react-router-dom";
import AddButton from "../../components/common/AddButton";
import { FirstBox, SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";
import ProductEditModal from "../product-details/ProductEditModal";
import NewTabs from "../../components/common/NewTabs";
import NewTable from "../../components/common/NewTable";
const AddNewProduct = () => {
  const tabs = [
    { label: "Ingots", value: "1" },
    { label: "Fractions", value: "2" },
  ];
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState();
  const [functionTypeParams, setFunctionTypeParams] = useState(null);
  const [open, setOpen] = useState(false);
  const [newOpen, setNewOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const initialTab = searchParams.get("tab") || "1";
  const [value, setValue] = useState(initialTab);
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
    orderBy: null,
    sort: null,
    inventory_type: value == "2" ? "fraction" : "ingot",
  });
  const { data, refetch, isFetching } = useFetchProductsInfo(queryParameters);
  const handleAddProduct = () => {
    setSelectedProductId(null);
    setOpen(true);
    setFunctionTypeParams("add");
  };
  useEffect(() => {
    setValue(initialTab);
  }, [initialTab]);
  const handleChange = (newValue) => {
    setValue(newValue);
    setSearchParams({ tab: newValue });
    if (newValue === "1") {
      setQueryParameters({
        ...queryParameters,
        page: 0,
        inventory_type: "ingot",
      });
    } else if (newValue === "2") {
      setQueryParameters({
        ...queryParameters,
        page: 0,
        inventory_type: "fraction",
      });
    }
  };
  const handleClose = async () => {
    setOpen(false);
  };

  const IngotsColumns = ItemsColumns([
    {
      field: "nameEn",
      headerName: "Name",
    },
    {
      field: "weight",
      add: "Gm",
    },
    {
      field: "stored_fees",
      headerName: "Storage Fees",
      add: "EGP",
    },
    { field: "inventory_amount", headerName: "Inventory Amount" },
    { field: "sellCount", headerName: "Available to sell" },
    {
      field: "availability",
      headerName: "Product Status",
      specialRender: "availability",
    },
    {
      field: "action",
      headerName: "Actions",
      actions: {
        view: (params) =>
          navigate(`/product-details/${params?.row?.id}`, {
            state: {
              product: params?.row,
            },
          }),
        edit: (params) => {
          setSelectedProduct(params?.row);

          setNewOpen(true);
        },
      },
    },
  ]);
  const fractionsColumns = ItemsColumns([
    { field: "nameEn", headerName: "Name" },
    { field: "weight", add: "Gm" },
    { field: "inventory_amount", headerName: "Inventory Amount" },
    { field: "sellCount", headerName: "Available to sell" },
    {
      field: "availability",
      headerName: "Product Status",
      specialRender: "availability",
    },
    {
      field: "action",
      headerName: "Actions",
      actions: {
        view: (params) =>
          navigate(`/product-details/${params?.row?.id}`, {
            state: {
              product: params?.row,
            },
          }),
        edit: (params) => {
          setSelectedProduct(params?.row);
          setNewOpen(true);
        },
      },
    },
  ]);

  return (
    <Box>
      <DashboardMainHeader />
      <Header title="Gold Products" marginL={"20px"} />
      <ProductModal
        value={value}
        queryParameters={queryParameters}
        fetchData={() => refetch(queryParameters)}
        setProducts={setProducts}
        products={products}
        productId={selectedProductId}
        functionTypeParams={functionTypeParams}
        open={open}
        onClose={handleClose}
        label={"Save"}
      />
      <ProductEditModal
        open={newOpen}
        setOpen={setNewOpen}
        product={selectedProduct || {}}
        fetchData={() => {
          refetch(queryParameters);
        }}
      />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 2,
          p: 2,
          justifyContent: "end",
        }}
      >
        <AddButton
          title={"+ New Product"}
          onClick={() => {
            handleAddProduct();
          }}
        />
      </Box>

      <SecondBox>
        <FirstBox m="40px 0 0 0" height="100%">
          <Box
            m="40px 0 0 0"
            height="auto"
            sx={{ ...DataGridStyle, overflowX: "auto" }}
          >
            <TabContext value={value}>
              <Box
                sx={{
                  width: "100%",
                  paddingTop: "2%",
                  paddingBottom: "1%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <NewTabs value={value} onChange={handleChange} tabs={tabs} />
              </Box>
              <TabPanel value="1">
                <Box
                  sx={{
                    height: "auto",
                    width: "100%",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                  }}
                >
                  <NewTable
                    columns={IngotsColumns}
                    isLoading={isFetching}
                    data={data?.result}
                    totalPages={data?.totalPages}
                    totalItems={data?.totalItems}
                    handlePageChange={(newPage) =>
                      handlePageChange(
                        newPage,
                        queryParameters,
                        setQueryParameters
                      )
                    }
                    currentPage={queryParameters}
                    onCellClick={(params) => {
                      if (params.field !== "action") {
                        navigate(`/product-details/${params?.row?.id}`, {
                          state: {
                            product: params?.row,
                          },
                        });
                      }
                    }}
                  />
                  {/* <DataGrid
                    components={{
                      LoadingOverlay: () =>
                        CustomLoadingOverlay({ columns: 7 }),
                    }}
                    disableSelectionOnClick
                    sx={{
                      cursor: "pointer",
                      backgroundColor: "#ffffff",
                      height: "650px",
                      minWidth: "1000px",
                    }}
                    loading={isFetching}
                    pageSize={10}
                    rowCount={data?.totalItems}
                    rows={isFetching ? [] : data?.result}
                    columns={IngotsColumns}
                    onPageChange={(p) =>
                      handlePageChange(p, queryParameters, setQueryParameters)
                    }
                    rowsPerPageOptions={[10]}
                    totalPages={data?.totalPages}
                    localeText={{
                      noRowsLabel: "No gold ingots available",
                    }}
                    onCellClick={(params) => {
                      if (params.field !== "action") {
                        navigate(`/product-details/${params?.id}`, {
                          state: {
                            product: params?.row,
                          },
                        });
                      }
                    }}
                  /> */}
                </Box>
              </TabPanel>
              <TabPanel value="2">
                <Box
                  sx={{
                    height: "auto",
                    width: "100%",
                    justifyContent: "center",
                    background: "#ffffff",
                  }}
                >
                  <NewTable
                    columns={fractionsColumns}
                    isLoading={isFetching}
                    data={data?.result}
                    totalPages={data?.totalPages}
                    totalItems={data?.totalItems}
                    handlePageChange={(newPage) =>
                      handlePageChange(
                        newPage,
                        queryParameters,
                        setQueryParameters
                      )
                    }
                    currentPage={queryParameters}
                    onCellClick={(params) => {
                      console.log("params", params);
                      if (params.field !== "action") {
                        navigate(`/product-details/${params?.row?.id}`, {
                          state: {
                            product: params?.row,
                          },
                        });
                      }
                    }}
                  />
                  {/* <DataGrid
                    components={{
                      LoadingOverlay: () =>
                        CustomLoadingOverlay({ columns: 7 }),
                    }}
                    disableSelectionOnClick
                    sx={{
                      cursor: "pointer",
                      backgroundColor: "#ffffff",
                      height: "650px",
                      minWidth: "1000px",
                    }}
                    pageSize={10}
                    rowCount={data?.totalItems}
                    loading={isFetching}
                    rows={isFetching ? [] : data?.result}
                    columns={fractionsColumns}
                    onPageChange={(p) =>
                      handlePageChange(p, queryParameters, setQueryParameters)
                    }
                    rowsPerPageOptions={[10]}
                    totalPages={data?.totalPages}
                    localeText={{
                      noRowsLabel: "No gold fractions available",
                    }}
                    onCellClick={(params) => {
                      if (params.field !== "action") {
                        navigate(`/product-details/${params?.id}`, {
                          state: {
                            product: params?.row,
                          },
                        });
                      }
                    }}
                  /> */}
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        </FirstBox>
      </SecondBox>
    </Box>
  );
};

export default AddNewProduct;
