import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DataGridStyle } from "../../styles";
import Header from "../../components/Header";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { TabContext, TabPanel } from "@mui/lab";
import FirstAdminTabPanel from "./ViewAdminsPanel/FirstAdminTabPanel";
import SecondAdminTabPanel from "./ViewAdminsPanel/SecondAdminTabPanel";
import ThirdAdminTabPanel from "./ViewAdminsPanel/ThirdAdminTabPanel";
import AddNewAdminModal from "./AddNewAdminModal";
import AddButton from "../../components/common/AddButton";
import NewTabs from "../../components/common/NewTabs";
import { useSearchParams } from "react-router-dom";

const AddNewUser = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialTab = searchParams.get("tab") || "1";
  const [value, setValue] = useState(initialTab);
  const [openDialog, setOpenDialog] = useState(false);
  const tabs = [
    { label: "Admins", value: "1" },
    { label: "Requests", value: "2" },
    { label: "Logs", value: "3" },
  ];
  useEffect(() => {
    setValue(initialTab);
  }, [initialTab]);
  const handleChange = (newValue) => {
    setValue(newValue);
    setSearchParams({ tab: newValue });
  };
  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = async () => {
    setOpenDialog(false);
  };
  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Admins" marginL={"25px"} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
          gap: 2,
          pr: 2,
        }}
      >
        <AddButton
          title={"+ New Admin"}
          onClick={() => {
            handleOpen();
          }}
        />
      </Box>
      <Box
        m="20px"
        sx={{
          height: "auto",
          background: "#FFF",
          borderRadius: "12px",
          boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
          <TabContext value={value}>
            <Box
              sx={{
                width: "100%",
                paddingTop: "5%",
                paddingBottom: "1%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <NewTabs tabs={tabs} value={value} onChange={handleChange} />
            </Box>
            <TabPanel value="1">
              <FirstAdminTabPanel />
            </TabPanel>
            <TabPanel value="2">
              <SecondAdminTabPanel />
            </TabPanel>
            <TabPanel value="3">
              <ThirdAdminTabPanel />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
      <AddNewAdminModal
        open={openDialog}
        onClose={handleClose}
        label={"Save"}
      />
    </Box>
  );
};

export default AddNewUser;
