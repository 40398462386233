import {
  Divider,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
} from "@mui/material";
import { formDate } from "../../../services/helpers";
const FirstTicketPanel = ({
  ticketsdetails,
  data,
  showReassignText,
  selectedTopic,
  handleReassignClick,
  handleTopicChange,
  AssignedAdmins,
  MenuProps,
  latestDate,
  isLoading,
}) => {
  return (
    <div>
      <div className="flex items-start mt-2">
        <p className="text-[#666666] text-lg">Ticket ID :</p>
        <p className="text-[#666666] text-lg ml-2">
          {isLoading ? (
            <Skeleton sx={{ width: 200 }} />
          ) : (
            `#${ticketsdetails?.id}`
          )}
        </p>
      </div>
      <hr className="text-[#F5F5F5] border-t-[1px] my-3   " />

      <div className=" grid md:flex items-center">
        <div className="flex items-center  md:w-1/2">
          <p className=" text-[#66666] text-lg">Ticket Subject :</p>
          <p className=" text-[#66666] text-lg ml-2">
            {isLoading ? <Skeleton sx={{ width: 200 }} /> : data?.subject}
          </p>
        </div>
        <hr className="text-[#F5F5F5] border-t-[1px] my-3 md:hidden   " />

        <div className="flex items-center">
          <p className=" text-[#66666] text-lg">Category :</p>
          <p className=" text-[#66666] text-lg ml-2">Orders</p>
        </div>
      </div>
      <hr className="text-[#F5F5F5] border-t-[1px] my-3    " />
      <div className="grid md:flex items-center ">
        <div className="flex items-center md:w-1/2">
          <p className="text-[#666666] text-base">Creation Date :</p>
          <p className=" text-[#66666] text-base ml-2">
            {isLoading ? (
              <Skeleton sx={{ width: 200 }} />
            ) : (
              formDate(data?.created)
            )}
          </p>
        </div>
        <hr className="text-[#F5F5F5] border-t-[1px] my-3 md:hidden   " />

        <div className="flex items-center">
          <p className=" text-[#66666] text-base">Last Response Date :</p>
          <p className=" text-[#66666] text-base ml-2">
            {isLoading ? (
              <Skeleton sx={{ width: 200 }} />
            ) : latestDate === "Invalid Date" ? (
              ""
            ) : (
              latestDate
            )}
          </p>
        </div>
      </div>

      <hr className="text-[#F5F5F5] border-t-[1px] my-3    " />

      <div className="flex items-center">
        <p className=" text-[#66666] text-lg">Feedback :</p>
        <p className=" text-[#66666] text-lg ml-2">
          {isLoading ? <Skeleton sx={{ width: 200 }} /> : data?.feedback}
        </p>
      </div>
      <Divider
        sx={{
          backgroundColor: "#F5F5F5",
          my: 2,
        }}
      />
      <div className="grid md:flex items-center justify-between whitespace-nowrap">
        <div className="flex items-center md:w-1/2">
          <p className=" text-[#66666] text-lg ">Assign to :</p>
          {showReassignText === false && selectedTopic === null ? (
            <p className=" text-[#66666] text-lg ml-2">
              {isLoading ? <Skeleton sx={{ width: 200 }} /> : data?.assignName}
            </p>
          ) : showReassignText === false && selectedTopic?.full_name ? (
            <p className=" text-[#66666] text-lg ">
              {isLoading ? (
                <Skeleton sx={{ width: 200 }} />
              ) : (
                selectedTopic?.full_name
              )}
            </p>
          ) : (
            <FormControl sx={{ width: "100%" }}>
              <Select
                displayEmpty
                onChange={handleTopicChange}
                value={selectedTopic || ""}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected?.length === 0) {
                    return <em>Assign to</em>;
                  }
                  return selected?.full_name;
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: 1,
                    borderColor: "#917244",
                  },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      border: 1,
                      borderColor: "#917244",
                    },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 1,
                      borderColor: "#917244",
                    },
                }}
              >
                {AssignedAdmins?.map((item) => (
                  <MenuItem key={item?.id} value={item}>
                    {item?.full_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        {isLoading ? (
          <Skeleton sx={{ width: 200 }} />
        ) : (
          data?.status !== 4 && (
            <p
              className=" text-[#917244] text-lg cursor-pointer"
              onClick={handleReassignClick}
            >
              {isLoading ? (
                <Skeleton sx={{ width: 200 }} />
              ) : data?.assignName ? (
                "Reassign"
              ) : (
                "Assign"
              )}
            </p>
          )
        )}
      </div>
    </div>
  );
};

export default FirstTicketPanel;
