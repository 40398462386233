import React from "react";
import {
  Button,
  Box,
  Typography,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useRejectTransactionReasonsDropDown } from "../../../services/apis/transactionRejectionReasons";

const rejectReasons = [
  { reason: "reason 1" },
  { reason: "reason 2" },
  { reason: "reason 3" },
  { reason: "reason 4" },
  { reason: "reason 5" },
];
const RejectionDialog = ({
  openAlert,
  handleCloseAlert,
  handleReject,
  setRejectionReason,
  rejectionReason,
  label,
  message,
  disabled,
  ...props
}) => {
  const { data } = useRejectTransactionReasonsDropDown();
  return (
    <Dialog
      open={openAlert}
      onClose={handleCloseAlert}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          borderRadius: "24px",
        },
      }}
    >
      <DialogContent
        sx={{
          padding: { xs: "16px", sm: "40px" },
          height: { xs: "auto", sm: "431px" },
          borderRadius: "24px",
          background: "#FFF",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          paddingBottom: "15%",
        }}
      >
        <Box
          sx={{
            width: "90%",
            height: "60%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
            >
              <path
                d="M31.9987 16.6669C33.1033 16.6669 33.9987 17.5624 33.9987 18.6669V34.6669C33.9987 35.7715 33.1033 36.6669 31.9987 36.6669C30.8941 36.6669 29.9987 35.7715 29.9987 34.6669V18.6669C29.9987 17.5624 30.8941 16.6669 31.9987 16.6669Z"
                fill="#917244"
              />
              <path
                d="M31.9987 45.3336C33.4715 45.3336 34.6654 44.1397 34.6654 42.6669C34.6654 41.1942 33.4715 40.0003 31.9987 40.0003C30.5259 40.0003 29.332 41.1942 29.332 42.6669C29.332 44.1397 30.5259 45.3336 31.9987 45.3336Z"
                fill="#917244"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.33203 32.0003C3.33203 16.1681 16.1665 3.33359 31.9987 3.33359C47.8309 3.33359 60.6654 16.1681 60.6654 32.0003C60.6654 47.8324 47.8309 60.6669 31.9987 60.6669C16.1665 60.6669 3.33203 47.8324 3.33203 32.0003ZM31.9987 7.33359C18.3757 7.33359 7.33203 18.3772 7.33203 32.0003C7.33203 45.6233 18.3757 56.6669 31.9987 56.6669C45.6217 56.6669 56.6654 45.6233 56.6654 32.0003C56.6654 18.3772 45.6217 7.33359 31.9987 7.33359Z"
                fill="#917244"
              />
            </svg>
          </Box>
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Lato",
              fontSize: { xs: "16px", sm: "20px" },
              fontStyle: "normal",
              fontWeight: 600,
            }}
          >
            {message}
          </Typography>
          <Box sx={{ width: "100%" }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#4C4C4C",
                marginBottom: "5px",
                marginTop: "15px",
              }}
            >
              Select Rejection reason
            </Typography>
            <FormControl sx={{ width: "97%" }}>
              <Select
                placeholder="Select Rejection reason"
                displayEmpty
                onChange={(e) => {
                  setRejectionReason(e.target.value);
                }}
                value={rejectionReason || ""}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>Select Rejection reason</em>;
                  }
                  return (
                    data?.find((item) => item?.id === selected)?.reason_en ||
                    selected
                  );
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {data?.map((item) => (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.reason_en}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: { xs: "column", sm: "row" },
              width: "100%",
            }}
          >
            <Button
              disabled={disabled}
              variant="contained"
              sx={{
                width: "100%",
                flexDirection: { xs: "column", sm: "row" },
                borderRadius: "6px",
                fontSize: "18px",
                background: "#917244",
                height: "46px",
                fontWeight: 600,
                "&:hover": {
                  background: "#917244",
                },
              }}
              onClick={handleReject}
            >
              {label}
            </Button>

            <Button
              variant="outlined"
              sx={{
                width: "100%",
                flexDirection: { xs: "column", sm: "row" },
                borderRadius: "6px",
                height: "46px",
                color: "#917244",
                fontWeight: 600,
                borderColor: "#917244",
                fontSize: "18px",
                "&:hover": {
                  borderColor: "#917244",
                },
              }}
              onClick={handleCloseAlert}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RejectionDialog;
