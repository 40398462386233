import React, { useState } from "react";
import { Drawer } from "@mui/material";
import { IoFilterOutline } from "react-icons/io5";
import closeCircle from "../../../svg/new-close-circle.svg";
import useUserFilterData from "../../../zustand/useUserFilterData";
import TransactionType from "./TransactionType";
import TransactionDateFilter from "./TransactionDateFilter";

const GoldFilterDrawer = ({ data, setData }) => {
  const {
    date_from,
    date_to,
    setDateFrom,
    setDateTo,
    goldTransactionType,
    setGoldTransactionType,
  } = useUserFilterData();
  const [open, setOpen] = useState(false);
  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(isOpen);
  };

  return (
    <div>
      <IoFilterOutline
        size={25}
        style={{ cursor: "pointer" }}
        onClick={toggleDrawer(true)} // Open the drawer when clicked
      />
      <Drawer
        anchor="right" // Open drawer from the right
        open={open}
        onClose={toggleDrawer(false)} // Close the drawer when needed
      >
        <div className="w-96 p-8">
          <img
            src={closeCircle}
            alt="close"
            className="mb-8 cursor-pointer"
            onClick={toggleDrawer(false)}
          />
          {/* //? <--------------Date-------------> */}
          <TransactionDateFilter />
          {/* //! transaction type */}
          <TransactionType />
          {/* //? Filter and Reset Button */}
          <div className="flex items-center gap-2 justify-center mt-10">
            <button
              onClick={() => {
                setOpen(false); // Directly close the drawer
                setData({
                  ...data,
                  page: 0,
                  date_to: date_to,
                  date_from: date_from,
                  goldTransactionType: goldTransactionType.includes("All")
                    ? [1, 2]
                    : goldTransactionType.includes("Buy") &&
                      goldTransactionType.includes("Sell")
                    ? [1, 2]
                    : goldTransactionType.includes("Buy") &&
                      !goldTransactionType.includes("Sell")
                    ? [1]
                    : !goldTransactionType.includes("Buy") &&
                      goldTransactionType.includes("Sell")
                    ? [2]
                    : [1, 2],
                  //   goldTransactionType: goldTransactionType.includes("All")
                  //     ? [1, 2]
                  //     : goldTransactionType.includes("Buy")
                  //     ? [1]
                  //     : goldTransactionType.includes("Sell")
                  //     ? [2]
                  //     : [],
                });
              }}
              className="text-xs font-medium px-16 py-3 font-ibmArabic bg-[#917244] rounded-xl text-white"
            >
              Filter
            </button>
            <button
              onClick={() => {
                setDateFrom("");
                setDateTo("");
                setGoldTransactionType([]);
                setData({
                  ...data,
                  date_from: null,
                  date_to: null,
                  page: 0,
                  limit: 10,
                  goldTransactionType: [1, 2],
                });
              }}
              className="text-xs font-medium px-16 py-3 font-ibmArabic   border border-[#917244] rounded-xl text-[#917244]"
            >
              Reset
            </button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default GoldFilterDrawer;
