import { create } from "zustand";

const useUserFilterData = create((set) => ({
  date_from: "",
  date_to: "",
  kyc_status: [], // For document status filter
  is_online: [], // For online status filter
  user_groups: [], // For user status filter (active/blocked)
  goldTransactionType: [], // For gold Transaction Type
  setDateFrom: (date) => set({ date_from: date }),
  setDateTo: (date) => set({ date_to: date }),
  setKycStatus: (status) => set({ kyc_status: status }), // Set document status filter
  setOnlineStatus: (status) => set({ is_online: status }), // Set online status filter
  setUserGroups: (groups) => set({ user_groups: groups }), // Set user groups filter
  setGoldTransactionType: (status) => set({ goldTransactionType: status }), // Set gold Transaction Type
}));

export default useUserFilterData;
