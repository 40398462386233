import { Divider, Skeleton } from "@mui/material";
import React from "react";

const ThirdTicketPanel = ({ handleNavigation, data, isLoading }) => {
  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center mt-2">
          <p className=" text-[#66666] text-lg">User name : </p>
          <p className=" text-[#66666] text-lg">
            {isLoading ? (
              <Skeleton sx={{ width: 200 }} />
            ) : (
              data?.user?.firstName + " " + data?.user?.lastName
            )}
          </p>
        </div>
        <div className="flex items-center">
          <p
            className=" text-[#917244] text-sm cursor-pointer underline mt-2"
            onClick={handleNavigation}
          >
            See all User Tickets
          </p>
        </div>
      </div>
      <Divider
        sx={{
          backgroundColor: "#F5F5F5",
          my: 2,
        }}
      />
      <div className="flex items-center ">
        <p className="text-lg text-[#666666]">Phone number :</p>

        <p className=" text-[#66666] text-lg ml-2">
          {isLoading ? <Skeleton sx={{ width: 200 }} /> : 0 + data?.user?.phone}
        </p>
      </div>
      <Divider
        sx={{
          backgroundColor: "#F5F5F5",
          my: 2,
        }}
      />
      <div className="flex items-center ">
        <p className="text-lg text-[#666666]">Mail :</p>
        <p className=" text-[#66666] text-lg ml-2">
          {isLoading ? <Skeleton sx={{ width: 200 }} /> : data?.user?.email}
        </p>
      </div>
      <Divider
        sx={{
          backgroundColor: "#F5F5F5",
          my: 2,
        }}
      />
      <div className="flex items-center ">
        <p className="text-lg text-[#666666]">National ID Name :</p>
        <p className=" text-[#66666] text-lg ml-2">
          {isLoading ? (
            <Skeleton sx={{ width: 200 }} />
          ) : (
            data?.user?.national_name
          )}
        </p>
      </div>
      <Divider
        sx={{
          backgroundColor: "#F5F5F5",
          my: 2,
        }}
      />
      <div className="flex items-center ">
        <p className="text-lg text-[#666666]">National ID :</p>

        <p className=" text-[#66666] text-lg ml-2">
          {isLoading ? (
            <Skeleton sx={{ width: 200 }} />
          ) : (
            data?.user?.nationalId
          )}
        </p>
      </div>
      <Divider
        sx={{
          backgroundColor: "#F5F5F5",
          my: 2,
        }}
      />
      <div className="flex items-center ">
        <p className="text-lg text-[#666666]">User Status :</p>

        <p
          style={{
            color: data?.user?.deleted_at === null ? "#917244" : "#FF453A",
            fontSize: "18px",
            marginLeft: "10px",
          }}
        >
          {isLoading ? (
            <Skeleton sx={{ width: 200 }} />
          ) : data?.user?.deleted_at === null ? (
            "Active"
          ) : (
            "Blocked"
          )}
        </p>
      </div>
    </div>
  );
};

export default ThirdTicketPanel;
